import React, { Component } from "react";
import {Container, Modal, Form, Button, Row,Col, InputGroup, FormControl} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthService from "../providers/auth/AuthService.js";





export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.state = {
      cardHidden: true,
      gcid: "",
      gcidError: true,
      gcidErrorText: "",
      uid: "",
      uidError: true,
      uidErrorText : "",
      password: "",
      passwordError: true,
      passwordErrorText: "",
      passwordType: "password",
      passwordShowLabel: "show"
    }

    this.myRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.togglePasswordShow = this.togglePasswordShow.bind(this);
    this.checkGcid = this.checkGcid.bind(this);
    this.checkUid = this.checkUid.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);

  };



  componentDidMount() {
    var currentGcid = this.auth.getGcid() ?? "";
    var currentUser = this.auth.getUser() ?? "";
    this.setState({
      gcid: currentGcid,
      uid : currentUser
    });
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      1000
    );
    this.myRef.current.focus();
  }

  togglePasswordShow(){
    let lbl = this.state.passwordShowLabel;
    if (lbl === "show") {
      this.setState({
        passwordShowLabel: "hide",
        passwordType: "text"
      });
    } else {
      this.setState({
        passwordShowLabel: "show",
        passwordType: "password"
      });
    }
  }

  checkGcid(toCheck){
    var testVal = toCheck ?? this.state.gcid.toString();
    if (testVal.length < 2) {
      this.setState({
        gcidErrorText: (
          <small className="text-danger">
            your glasschain company id (gcid) must be filled in. e.g. <i>dmo</i>.
          </small>
        )
      });
      this.setState({ gcidError: true });
      return false;
    }
    else {
      this.setState({ 
        gcidError: false,
        gcidErrorText: "" 
      });
      return true;
    }
  }

  checkUid(toCheck){
    var testVal = toCheck ?? this.state.uid.toString();
    if (testVal.length < 2) {
      this.setState({
        uidErrorText: (
          <small className="text-danger">
            your glasschain user id must be filled in. This is usually your email address.
          </small>
        )
      });
      this.setState({ uidError: true });
      return false;
    }
    else {
      this.setState({ 
         uidError: false,
         uidErrorText: "" 
        });
      return true;
    }
  }

  checkPassword(toCheck){
    var testVal = toCheck ?? this.state.password.toString();
    if (testVal.length < 6) {
      this.setState({
        passwordErrorText: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
            </small>
        )
      });
      return false;
    }
    else {
      this.setState({ 
        passwordError: false ,
        passwordErrorText: ""
      });
      return true;
    }
  }

  handleChange(ev){
    // assumes that the state variable is the same name as the event target name
    this.setState({[ev.target.name] : [ev.target.value]});
    switch(ev.target.name){
      case ("gcid"):
        this.checkGcid(ev.target.value);
        break;
      case ("uid"):
        this.checkUid(ev.target.value);
        break;
      case ("password"):
        this.checkPassword(ev.target.value);
        break;
      default:
        break;
    }
  }

  validateInputs(returnOnFirstFalse = true){ 
    var result = true;
    result = this.checkGcid();
    if (returnOnFirstFalse && (!result)) return false;
    result = this.checkUid();
    if (returnOnFirstFalse && (!result)) return false;
    result = this.checkPassword();
    if (returnOnFirstFalse && (!result)) return false;
    return result;
  }
    

  async handleLoginSubmit() {
    var isInputOk = this.validateInputs(true);
    if (!isInputOk) return;

    // let's try to authorize the combo
    try{
      var loginSuccessful = await this.auth.loginSuccessful(this.state.gcid, this.state.uid, this.state.password, true);
      //var loginSuccessful = this.auth.loggedIn();
      if (!loginSuccessful){
        loginSuccessful = await this.auth.loginSuccessful(this.state.gcid, this.state.uid, this.state.password, false);
      }
      if (!loginSuccessful){
        this.setState({
          gcidError: true,
          uidError: true,
          passwordError: true,
          passwordErrorText: (
            <small className="text-danger">
              Your gcid, user id and password combination could not be
              authorized.
            </small>
          )
        });
        return;
      }
      // do any cleanup here before launching the app
      this.props.history.replace('/'); 
    }
    catch(err){
      this.setState({
        passwordError: true,
        passwordErrorText: (
          <small className="text-danger">
            There was a problem logging you in [{err.toString()}]
          </small>
        )
      });
    }

  }








  render() {

    const showIcon = (this.state.passwordShowLabel==="show") ? "eye" : "eye-slash";

    return (
      <div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          show={true}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <Container>
                <Row>
                  <Col>Log In</Col>
                  <Col>
                    <img id="gclogo"
                      src={require("../assets/images/glasschain-logo.png")}
                      alt="glasschain logo"
                    />
                  </Col>
                </Row>
              </Container>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
                  <label><FontAwesomeIcon icon="building"/>&nbsp;&nbsp;Company
                  &nbsp;&nbsp;&nbsp;




                  </label><br/>
                  <InputGroup>
                    <FormControl 
                        placeholder={"company id"}
                        type="text"
                        value={this.state.gcid}
                        name = "gcid"
                        onChange={event => this.handleChange(event)}
                        ref = {this.myRef}
                    />    
                  </InputGroup>
                  <label>{this.state.gcidErrorText}</label> 
                  <br/>

                  <label><FontAwesomeIcon icon="user"/>&nbsp;&nbsp;User Id</label>
                  <InputGroup>
                    <FormControl 
                        placeholder={"user name"}
                        type="text"
                        value={this.state.uid}
                        name = "uid"
                        onChange={event => this.handleChange(event)}
                    />   
                  </InputGroup>
                  <label>{this.state.uidErrorText}</label> 
                  <br/>

                  <label><FontAwesomeIcon icon="unlock"/>&nbsp;&nbsp;Password</label>
                  <InputGroup>
                    <FormControl 
                        placeholder="password"
                        type={this.state.passwordType} //"password"
                        autoComplete="off"
                        name = "password"
                        onChange={event => this.handleChange(event)}
                    /> 
                    <InputGroup.Append>
                      <Button 
                        variant="outline-secondary"
                        onClick={this.togglePasswordShow}>
                          <FontAwesomeIcon icon={showIcon}/>
                      </Button>
                    </InputGroup.Append> 
                  </InputGroup>
                  <label>{this.state.passwordErrorText}</label> 
                  </div>
                  <br/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary"  onClick={this.handleLoginSubmit}>
              Log In
            </Button>
          </Modal.Footer>
        </Modal>
   

      </div>

    );
  }
}
