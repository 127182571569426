import React, { Component } from "react";
import { Card, InputGroup, Button, Container, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

import GlasschainHttpClient from "providers/http/GlasschainHttpClient";
import AuthService from "providers/auth/AuthService";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";


export default class NotificationRuleGroupAdminGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [
        { headerName: "SubscriberId", field: "subscriberId", resizable: true, sortable: true, filter: true },
        { headerName: "Rule", field: "ruleId", resizable: true, sortable: true, filter: true },
        { headerName: "Group", field: "groupKey", resizable: true, sortable: true, filter: true },
        { headerName: "Group Value", field: "groupValue", resizable: true, sortable: true, filter: true}

      ],
      gridData: [],
      currentSelection: null,
      isLoading: true,
      showInputField: false
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

    this.gcid = this.auth.getGcid();

    this.refreshGridData = this.refreshGridData.bind(this);
    this.onBtnExportDataAsCsv = this.onBtnExportDataAsCsv.bind(this);
    this.onBtnDeleteSelectedRuleGroupSubscriber = this.onBtnDeleteSelectedRuleGroupSubscriber.bind(this);
    this.onBtnAddRuleGroupSubscriber = this.onBtnAddRuleGroupSubscriber.bind(this);
    this.onBtnCreateRuleGroupSubscriber = this.onBtnCreateRuleGroupSubscriber.bind(this);
    this.subscriberIdExists = this.subscriberIdExists.bind(this);
    this.fieldToggle = this.fieldToggle.bind(this);
  }

  async componentDidMount() {
    await this.refreshGridData();
    if (this.props.schemaId) {
      await this.refreshObservationSchema();
    }
  }

async componentDidUpdate(prevProps){
  if (prevProps.hadMasterUpdate != this.props.hadMasterUpdate){
    await this.refreshGridData();
  }
}
 

  async refreshGridData() {
    console.log("refreshing data");
    this.setState({ isLoading: true });
    var gridDataResponse = await this.httpClient.fetchNotificationSubscriberRuleGroupList(this.gcid);
    var newGridData = gridDataResponse.data;
    console.log("Grid data inside refresh func in admin grid: ")
    console.log(newGridData);
    this.setState({
      gridData: newGridData,
      isLoading: false,
      showInputField: false
    });
  }



  onEditGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };



  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    this.setState({ currentSelection: rowData });
  }

  // Controls
  onBtnExportDataAsCsv() {
    const saveParams = {
      allColumns: true,
      onlySelected: false,
      onlySelectedAllPages: false,
      skipHeader: false
    };
    this.gridApi.exportDataAsCsv(saveParams);
  }

  async onBtnDeleteSelectedRuleGroupSubscriber() {
    if (!this.state.currentSelection) {
      alert("Select a Rule/Group/Subscriber to delete!")
      return;
    }
    await this.httpClient.deleteNotificationRuleGroupSubscriber(this.gcid, this.state.currentSelection.id);
    this.setState({ currentSelection: null });
    await this.refreshGridData();
  }

  onBtnAddRuleGroupSubscriber() {
    this.setState({
      showInputField: true
    })
    //alert(this.state.showInputField);
  }

  subscriberIdExists(testSubscriberId) {
    var lTestSubscriberId = testSubscriberId.toLowerCase();
    for (var i = 0; i < this.state.gridData.length; i++) {
      if (this.state.gridData[i].id.toLowerCase() === lTestSubscriberId) {
        return true;
      }
    }
    return false;
  }

  async onBtnCreateRuleGroupSubscriber(event) {
    event.preventDefault();
    const selects = event.target.getElementsByTagName('select');
    const inputs = event.target.getElementsByTagName('input');
    let newSub =
    {
      gcid: this.gcid,
      ruleId: selects.addRuleId.value,
      groupKey: inputs.addGroupKey.value,
      groupValue: inputs.addGroupValue.value,
      subscriberId: inputs.addSubscriberId.value
    };

    console.group("New Rule/Group/Subscriber:");
    console.log(newSub);
    console.groupEnd();
    //if (this.subscriberIdExists(newSub.id)) {
     // alert('This subscriber Id already exists!');
    // return;
    //}
    // TBD confirm this combination doesn't already exist. 
    await this.httpClient.postAddNotificationRuleGroupSubscriber(this.gcid, newSub);
    await this.refreshGridData();
  }


  fieldToggle(){
    if(this.state.showInputField){
      this.setState({
        showInputField: false
      })
    } else {
      this.setState({
        showInputField: true
      })
    }
  }

  render() {

    const loadingIcon = (this.state.isLoading) ?
      <FontAwesomeIcon icon="sync-alt" color="green" spin /> :
      ""

    const divStyle = {
      width: "100%",
      height: "calc(45vh)",

      margin: "5px",
      //border: '5px solid black'
    };

    const inputForm =
      <form onSubmit={this.onBtnCreateRuleGroupSubscriber}>
        <input type="text" name="addSubscriberId" placeholder="Subscriber Id" required></input>
        <select type="select" name="addRuleId" placeholder="Rule" required>
            <option value="QA-REJECT">QA Reject</option>
            <option value="QA-PREINSPECTREJECT">Pre-Inspect Reject</option>
            <option value="QA-NEARREJECT">Near-Reject</option>
        </select>
        <input type="text" name="addGroupKey" placeholder="Group" value="grower" readOnly></input>
        <input type="text" name="addGroupValue" placeholder="Group Value" required></input>
        <input type="reset" name="reset" value="Reset" />
        <input type="submit" value="Submit"></input>
        <button onClick={this.fieldToggle}>Cancel</button>
      </form>

    const columnDefs = (this.state.gridSchema) ? this.state.gridSchema : [];


    const gridData = (this.state.gridData) ? this.state.gridData : [{}];

    return (
      <Card style={{ maxWidth: "2000px", padding: "5px" }}>
        <Card.Header>
          <span style={{ float: "left" }}>Subscriber Rule/Group List</span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <ReactTooltip />
        <Card.Body>
          <div style={{ width: "100%", height: "100%" }}>
            <Container fluid>
              <Row>
                <Col sm={7}>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Actions
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <p data-tip="Export Rule/Group/Subscriber List">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnExportDataAsCsv}>
                        <FontAwesomeIcon icon="file-export" />
                      </Button>
                    </p>
                    <p data-tip="Add Rule/Group/Subscriber">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnAddRuleGroupSubscriber}>
                        <FontAwesomeIcon icon="user-plus" />
                      </Button>
                    </p>
                    <p data-tip="Remove Rule/Group/Subscriber">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnDeleteSelectedRuleGroupSubscriber}>
                        <FontAwesomeIcon icon="user-slash" />
                      </Button>
                    </p>
                  </InputGroup>
                </Col>
                <Col sm={5}>

                </Col>
              </Row>
            </Container>

            <Container>
              {this.state.showInputField && inputForm}
            </Container>

            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={true}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}