import React, { Component } from "react";
import { Card, InputGroup, Button, Container, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

import GlasschainHttpClient from "providers/http/GlasschainHttpClient";
import AuthService from "providers/auth/AuthService";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";


export default class NotificationAdminGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [
        { headerName: "SubscriberId", field: "id", resizable: true, sortable: true, filter: true },
        { headerName: "Name", field: "name", resizable: true, sortable: true, filter: true },
        { headerName: "Role", field: "role", resizable: true, sortable: true, filter: true },
        { headerName: "Email", resizable: true, sortable: true, filter: true, cellRenderer: function (params) { return params.data.transports[0].address } }

      ],
      gridData: [],
      currentSelection: null,
      isLoading: true,
      showInputField: false,
      inputMode: null
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

    this.gcid = this.auth.getGcid();

    this.refreshGridData = this.refreshGridData.bind(this);
    this.onBtnExportDataAsCsv = this.onBtnExportDataAsCsv.bind(this);
    this.onBtnDeleteSelectedSubscriber = this.onBtnDeleteSelectedSubscriber.bind(this);
    this.onBtnAddSubscriber = this.onBtnAddSubscriber.bind(this);
    this.onBtnEditSelectedSubscriber = this.onBtnEditSelectedSubscriber.bind(this);
    this.onBtnHandleSubscriberChange = this.onBtnHandleSubscriberChange.bind(this);
    this.subscriberIdExists = this.subscriberIdExists.bind(this);
    this.fieldToggle = this.fieldToggle.bind(this);
  }

  async componentDidMount() {
    await this.refreshGridData();
    if (this.props.schemaId) {
      await this.refreshObservationSchema();
    }
  }

  componentWillUnmount() { }

  // componentDidUpdate(){
  //   alert(this.state.addUserID);
  // }

  async refreshGridData() {
    console.log("refreshing data");
    this.setState({ isLoading: true });
    var gridDataResponse = await this.httpClient.fetchNotificationSubscriberList(this.gcid);
    var newGridData = gridDataResponse.data;
    console.log("Grid data inside refresh func in admin grid: ")
    console.log(newGridData);
    this.setState({
      gridData: newGridData,
      isLoading: false,
      showInputField: false
    });
  }



  onEditGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };



  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    this.setState({ currentSelection: rowData });
  }

  // Controls
  onBtnExportDataAsCsv() {
    const saveParams = {
      allColumns: true,
      onlySelected: false,
      onlySelectedAllPages: false,
      skipHeader: false
    };
    this.gridApi.exportDataAsCsv(saveParams);
  }

  async onBtnDeleteSelectedSubscriber() {
    if (!this.state.currentSelection) {
      alert("Select a Subscriber to delete!")
      return;
    }
    await this.httpClient.deleteNotificationSubscriber(this.gcid, this.state.currentSelection.id);
    this.setState({ currentSelection: null });
    await this.refreshGridData();
    this.props.onMasterUpdate();
  }

  onBtnAddSubscriber() {
    this.setState({
      inputMode: "add",
      showInputField: true
    })
    //alert(this.state.showInputField);
  }

  async onBtnEditSelectedSubscriber() {
    if (!this.state.currentSelection) {
      alert("Select a Subscriber to edit!")
      return;
    }
    this.setState({
      inputMode: "edit",
      showInputField: true
    });
  }

  subscriberIdExists(testSubscriberId) {
    var lTestSubscriberId = testSubscriberId.toLowerCase();
    for (var i = 0; i < this.state.gridData.length; i++) {
      if (this.state.gridData[i].id.toLowerCase() === lTestSubscriberId) {
        return true;
      }
    }
    return false;
  }

  async onBtnHandleSubscriberChange(event) {
    event.preventDefault();
    const inputs = event.target.getElementsByTagName('input');
    let newSub =
    {
      id: inputs.addUserID.value,
      gcid: this.gcid,
      name: inputs.addUserName.value,
      nickname: null,
      role: inputs.addUserRole.value,
      active: true,
      transports: [
        { transportType: "email", address: inputs.addUserEmail.value, hasAdditionalAttribs: false, additionalAttribs: null }
      ]
    };

    console.group("New Subscriber:");
    console.log(newSub);
    console.groupEnd();

    var inputMode = this.state.inputMode;
    if (inputMode === "add"){
      if (this.subscriberIdExists(newSub.id)) {
        alert('This subscriber Id already exists!');
        return;
      }
      await this.httpClient.postAddNotificationSubscriber(this.gcid, newSub);
    }
    else if(inputMode==="edit") {
      await this.httpClient.postUpdateNotificationSubscriber(this.gcid, newSub);
    }


    await this.refreshGridData();
  }




  fieldToggle(){
    if(this.state.showInputField){
      this.setState({
        showInputField: false
      })
    } else {
      this.setState({
        showInputField: true
      })
    }
  }

  render() {

    const loadingIcon = (this.state.isLoading) ?
      <FontAwesomeIcon icon="sync-alt" color="green" spin /> :
      ""

    const divStyle = {
      width: "100%",
      height: "calc(45vh)",

      margin: "5px",
      //border: '5px solid black'
    };
  
    const inputForm = (this.state.inputMode && this.state.inputMode === "add" ) ?
      <form onSubmit={this.onBtnHandleSubscriberChange}>
        <input type="text" name="addUserID" placeholder="Subscriber Id" required></input>
        <input type="text" name="addUserName" placeholder="Name" required></input>
        <input type="text" name="addUserRole" placeholder="Role" required></input>
        <input type="email" name="addUserEmail" placeholder="Email" required></input>
        <input type="reset" name="reset" value="Reset" />
        <input type="submit" value="Submit"></input>
        <button onClick={this.fieldToggle}>Cancel</button>
      </form>
      : (this.state.inputMode && this.state.inputMode === "edit") ?
      <form onSubmit={this.onBtnHandleSubscriberChange}>
        <input type="text" name="addUserID" placeholder="Subscriber Id" value={this.state.currentSelection.id}readOnly></input>
        <input type="text" name="addUserName" placeholder="Name" defaultValue={this.state.currentSelection.name} required></input>
        <input type="text" name="addUserRole" placeholder="Role" defaultValue={this.state.currentSelection.role} required></input>
        <input type="email" name="addUserEmail" placeholder="Email" defaultValue={this.state.currentSelection.transports[0].address} required></input>
        <input type="reset" name="reset" value="Reset" />
        <input type="submit" value="Submit"></input>
        <button onClick={this.fieldToggle}>Cancel</button>
      </form>
      : "";    


    

    const columnDefs = (this.state.gridSchema) ? this.state.gridSchema : [];


    const gridData = (this.state.gridData) ? this.state.gridData : [{}];

    return (
      <Card style={{ maxWidth: "2000px", padding: "5px" }}>
        <Card.Header>
          <span style={{ float: "left" }}>Notification Subscriber List</span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <ReactTooltip />
        <Card.Body>
          <div style={{ width: "100%", height: "100%" }}>
            <Container fluid>
              <Row>
                <Col sm={7}>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Actions
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <p data-tip="Export Subscriber List">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnExportDataAsCsv}>
                        <FontAwesomeIcon icon="file-export" />
                      </Button>
                    </p>
                    <p data-tip="Add Subscriber">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnAddSubscriber}>
                        <FontAwesomeIcon icon="user-plus" />
                      </Button>
                    </p>
                    <p data-tip="Remove Subscriber">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnDeleteSelectedSubscriber}>
                        <FontAwesomeIcon icon="user-slash" />
                      </Button>
                    </p>
                    <p data-tip="Edit Subscriber">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnEditSelectedSubscriber}>
                        <FontAwesomeIcon icon="user-edit" />
                      </Button>
                    </p>
                  </InputGroup>
                </Col>
                <Col sm={5}>

                </Col>
              </Row>
            </Container>

            <Container>
              {this.state.showInputField && inputForm}
            </Container>

            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={true}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
