import React, { Component } from "react";
import { Card, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

import ModalAlertDialog from "../../modals/ModalAlertDialog.jsx";
import ModalSlideDialog from "../../modals/ModalSlideDialog.jsx";

import GlasschainHttpClient from "providers/http/GlasschainHttpClient";
import AuthService from "providers/auth/AuthService";
import OrgConfiguration from "../../../models/OrgConfiguration.js";
import GridLayout from "../../../models/GridLayout.js";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";


function tagsToString(tags){
  if (!tags) return "";
  var result = "";
  for (var i=0; i<tags.length; i++){
    if (tags[i]){
      result += tags[i].label + ", ";
    }
  }
  return result;
}


export default class ObservationsGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [],
      schema: null,
      gridData: [],
      isLoading: true,
      showSlides: false,
      slideInfo: { title: "", slides: [{ url: "", caption: "" }] },
      showAlert: false,
      alertTitle: "",
      alertType: "info",
      alertMessage: "",
      selectedRow: null
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();
    this.gcid = this.auth.getGcid();

    this.refreshObservationSchema = this.refreshObservationSchema.bind(this);
    this.refreshGridData = this.refreshGridData.bind(this);
    this.onBtnExportDataAsCsv = this.onBtnExportDataAsCsv.bind(this);
    this.onBtnDownloadDetailedDataAsCsv = this.onBtnDownloadDetailedDataAsCsv.bind(this);
    this.onViewUserImagesRequest = this.onViewUserImagesRequest.bind(this);
    this.onUserImagesClose = this.onUserImagesClose.bind(this);

    this.onBtnSaveLayout = this.onBtnSaveLayout.bind(this);
    this.onBtnResetLayout = this.onBtnResetLayout.bind(this);
    this.onBtnRestoreLayout = this.onBtnRestoreLayout.bind(this);

    this.showAlertDialog = this.showAlertDialog.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  async componentDidMount() {
    if (!this.auth.loggedIn()){
      this.props.onLoginExpire();
    }
    if (this.props.schemaId){
      await this.refreshObservationSchema();
      // tbd - including update the grid schema
    }
    // fetch data based on the search params
    if (this.props.searchParams){
      await this.refreshGridData();
    } 
  }

  componentWillUnmount() {}


  async componentDidUpdate(prevProps, prevState){
      if (this.props.schemaId != prevProps.schemaId){
        await this.refreshObservationSchema();
        // tbd - including update the grid schema
      }
      if (this.props.gridQueryString != prevProps.gridQueryString){
        await this.refreshGridData();
      }
  }

  async refreshObservationSchema(){
    /*
    var newSchema = await this.httpClient.fetchObservationSchema(this.props.schemaId);
    var cleanerGridSchema = newSchema.data.gridSchema.replace(/\\"/g, '"');
    // good god! you are NOT using eval! Are you mad?!
    var newGridSchema;
    eval('newGridSchema = new Object(' + cleanerGridSchema + ')');
    this.setState({
      gridSchema: newGridSchema
    });
    */
    //var schemaData = this.auth.getLocalSchema(this.props.schemaId);
    var fullSchema = await this.httpClient.fetchObservationFullSchema(this.props.schemaId);
    console.log("full schema: ");
    console.log(fullSchema);
    //var fullSchema = JSON.parse(schemaData.fullSchema);
    // now we have to BUILD the grid layout. 
    var newCleanGridSchema = GridLayout.toAgGridLayout(fullSchema.gridLayout);
    this.setState({
      schema: fullSchema,
      gridSchema: newCleanGridSchema
    });
  }

  async refreshGridData(){
    this.setState({isLoading: true});
    var gridDataResponse = await this.httpClient.fetchObservationList(this.props.searchParams);
    var newGridData = gridDataResponse.data;
    this.setState({
      gridData: newGridData,
      isLoading: false
    });
  }

  onEditGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var savedColState = JSON.parse(this.auth.getUserProp(GRIDCOLSTATE));
    if (savedColState) {
      this.gridColumnApi.setColumnState(savedColState);
    }
  };


  showAlertDialog(title, message, type){  //error warning info
    this.setState({alertTitle: title, alertMessage: message, alertType: type, showAlert: true});
  }

  onCloseAlert(){
    this.setState({showAlert: false});
  }

  onViewUserImagesRequest(){
    if (!this.state.selectedRow) {
      this.showAlertDialog("Select an Inspection", "Please select an observation first!", "error");
      return;
    }
    if (!this.state.selectedRow.data.Raw.userimages){
      this.showAlertDialog("The selected observation doesn't have any user images to display!", "error");
      return; 
    }
    const userImages = this.state.selectedRow.data.Raw.userimages;
    if (!userImages) return;
    //{ title: "", slides: [{ url: "", caption: "" }] }
    var slides = userImages.map((img) => {
      console.log(img);
      return {imgFileName: img.id + ".png", title: img.descript, caption: img.descript + (img.tags) ? tagsToString(img.tags): ""}
    });
    this.setState({
      showSlides: true,
      slideInfo: {title: "User Images", slides: slides}
    })
    //this.props.onViewUserImagesRequest(this.state.selectedRow.data.Raw.userimages);  
  }

  onUserImagesClose(){
    this.setState({showSlides: false});
  }

  

  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData =  selectedNodes[0].data; 
    this.setState({selectedRow: rowData});
    this.props.onDetailedGridRowChange(rowData);
  }

    // Controls
    onBtnExportDataAsCsv() {
      const saveParams = {
        allColumns: true,
        onlySelected: false,
        onlySelectedAllPages: false,
        skipHeader: false
      };
      this.gridApi.exportDataAsCsv(saveParams);
    }

    async onBtnDownloadDetailedDataAsCsv() {
      var searchParams = this.props.searchParams;
      await this.httpClient.fetchDetailedObservationDataAsCsv(searchParams);
    }

  
    onBtnSaveLayout() {
      var newGridColState = this.gridColumnApi.getColumnState();
      this.auth.setUserProp(this.state.schemaId+ "::" + GRIDCOLSTATE, JSON.stringify(newGridColState));
    }
  
    onBtnResetLayout() {
      this.gridColumnApi.resetColumnState();
      this.gridColumnApi.resetColumnGroupState();
      this.gridApi.setSortModel(null);
      this.gridApi.setFilterModel(null);
    }
  
    onBtnRestoreLayout() {
      var savedColState = JSON.parse(this.auth.getUserProp(this.state.schemaId+ "::" + GRIDCOLSTATE));
      if (savedColState) {
        this.gridColumnApi.setColumnState(savedColState);
      }
    }


  render() {

    const loadingIcon = (this.state.isLoading) ? 
      <FontAwesomeIcon icon="sync-alt" color="green" spin/> :
      ""

    const divStyle = {
      width: "100%",
      height: "calc(45vh)",
      
      margin: "5px",
      //border: '5px solid black'
    };

    const columnDefs = (this.state.gridSchema) ? this.state.gridSchema : [];


    const gridData = (this.state.gridData) ? this.state.gridData : [{}];

    return (
      <Card style={{maxWidth: "2000px", padding: "5px"}}>
        <Card.Header>
          <span style={{float: "left"}}>Observation List</span>
          <span style={{float: "right"}}>{loadingIcon}</span>
        </Card.Header>
        <ReactTooltip/>
        <ModalAlertDialog
          show={this.state.showAlert}
          title={this.state.alertTitle}
          iconType={this.state.alertType} // error|warning|info
          message={this.state.alertMessage}
          onClose={this.onCloseAlert}

        />
        <ModalSlideDialog
          gcid={this.gcid}
          slideType = {"user"}
          showSlides={this.state.showSlides}
          slideInfo={this.state.slideInfo}
          onRequestCriteriaSlidesClose={this.onUserImagesClose}
        />
        <Card.Body>
          <div style={{ width: "100%", height: "100%"}}>
            <Container fluid>
              <Row>
                <Col sm={7}>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Inspection
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <p data-tip="Export grid data to csv">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnExportDataAsCsv}>
                        <FontAwesomeIcon icon="file-export"/>
                      </Button>
                    </p>
                    <p data-tip="download ALL detailed observation data based on current search criteria">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnDownloadDetailedDataAsCsv}>
                        <FontAwesomeIcon icon="file-download"/>
                      </Button>
                    </p>
                    <p data-tip="Show images associated with observation">
                      <Button size="sm" variant="outline-info"
                        onClick={this.onViewUserImagesRequest}
                      >
                        <FontAwesomeIcon icon="images" />
                      </Button>
                    </p>
                  </InputGroup>
                </Col>
                <Col sm={5}>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Layout
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <p data-tip="save current grid layout">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnSaveLayout}>
                        <FontAwesomeIcon icon="save"/>
                      </Button>
                    </p>
                    <p data-tip="restore your saved grid layout">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnResetLayout}>
                        <FontAwesomeIcon icon="trash-restore"/>
                      </Button>
                    </p>
                    <p data-tip="restore default grid layout">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnRestoreLayout}>
                        <FontAwesomeIcon icon="bookmark"/>
                      </Button>
                    </p>
                  </InputGroup>
                </Col>
              </Row>
            </Container>

            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={true}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
