import React, { PureComponent, Component } from "react";
import { Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GlasschainHttpClient from "providers/http/GlasschainHttpClient";

function toChartData(apiData) {
  const labels = apiData.map((rec) => {
    return rec.source;
  });
  const data = apiData.map((rec) => {
    return rec.avg;
  });
  var result = { labels: labels, data: data };
  return result;
}

export default class ObservationsRankBarCard3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartLabels: ["Loading..."],
      chartData: [0],
      isLoading: true,
      moreCaret: "caret-up",
      isCollapsed: false
    };

    this.httpClient = new GlasschainHttpClient();
    this.refreshChartData = this.refreshChartData.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleHorizontal = this.toggleHorizontal.bind(this);
  }

  async componentDidMount() {
    if (this.props.searchParams && !this.state.isCollapsed) {
      await this.refreshChartData();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.gridQueryString != prevProps.gridQueryString) {
      await this.refreshChartData();
    }
  }

  async refreshChartData() {
    if (this.state.isCollapsed) return; // don't bother!
    this.setState({ isLoading: true });
    var rawResponse = await this.httpClient.requestObsRankList(this.props.searchParams, this.props.rankSchema);
    //var rawResponse = await this.httpClient.fetchObsRanchRankList(
    var newChartData = toChartData(rawResponse.data);
    this.setState({
      chartLabels: newChartData.labels,
      chartData: newChartData.data,
      isLoading: false,
    });
  }

  toggleCollapse() {
    let newIsCollapsed = !this.state.isCollapsed;
    let newCaretState = newIsCollapsed ? "caret-down" : "caret-up";
    this.setState({
      isCollapsed: newIsCollapsed,
      moreCaret: newCaretState,
    });
  }

  toggleHorizontal() {
    let newIsCollapsed = !this.state.isCollapsed;
    let newCaretState = newIsCollapsed ? "caret-down" : "caret-up";
    this.setState({
      isCollapsed: newIsCollapsed,
      moreCaret: newCaretState,
    });
  }

  render() {
    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon="sync-alt" color="green" spin />
    ) : (
      ""
    );

    const chartState = {
      labels: this.state.chartLabels,
      datasets: [
        {
          label: this.props.rankSchema.title,
          backgroundColor: "rgba(75,192,192,1)",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
          data: this.state.chartData,
        },
      ],
    };
    return (
      <Card style={{ responsive: "true", padding: "5px" }}>
        <Card.Header>
          <span style={{ float: "left" }}>
            <FontAwesomeIcon icon={this.state.moreCaret} onClick={this.toggleCollapse}/>
            &nbsp;&nbsp;{this.props.rankSchema.title}
          </span>
          <span style={{ float: "right" }}><FontAwesomeIcon icon={(this.props.isMultiRow? "caret-right": "caret-left")} onClick={this.props.onRankingsHorizontalChange} />{loadingIcon}</span>
        </Card.Header>
        {!this.state.isCollapsed ? (
        <Card.Body>
            <Bar
              data={chartState}
              options={{
                title: {
                  display: true,
                  text: this.props.rankSchema.title,
                  fontSize: 10,
                  stacked: true
                },
                legend: {
                  display: false,
                  position: "right",
                },
              }}
              height="100%"
            />
            </Card.Body>
          ) : (
            <span />
          )}
      </Card>
    );
  }
}
