import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import HealthPanel from "../components/local/panels/HealthPanel.jsx";
import ObservationsPanel from "../components/local/panels/ObservationsPanel.jsx";

import AuthService from "../providers/auth/AuthService.js";



export default class HomePage extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.hasAdminScope = this.auth.hasScope("qaadmin");
  }

  
  render() {
    return (
      <div className="main-content" style={{ padding: "5px" }}>
        <Row>
          <Col xs={12}>
            <HealthPanel />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ObservationsPanel 
              onLoginExpire = {this.props.onLoginExpire}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
