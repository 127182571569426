import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class CircleIcon extends Component {
    constructor(props){
        super(props);
        this.state = {
            size: this.props.size || "1x",
            icon: this.props.icon, // must be included in the index.js icon set found in the fontawesome.js library
            color: this.props.color || "black",
            rotate: this.props.rotate || "360",
            onClick: this.props.onClick
        }

    }


    render() {
        let transformString = "shrink-10 up-.5";
        if (this.props.rotate !== "360"){
            transformString = transformString + " rotate-" + this.state.rotate;
        }
        const sz = this.props.size || "1x";
        return(
            <div onClick={this.props.onClick}>
                    <FontAwesomeIcon
                    size = {this.props.size}
                    icon = {this.props.icon}
                    color = {this.state.color}
                    mask='circle'
                    transform = {transformString}/>
            </div>

 
        );
    }
}