import RequestService from './RequestService.js';
import AppConfig from '../config/ConfigService.js';
import AuthService from '../auth/AuthService.js'


export default class GlasschainHttpClient {
    // Initializing important variables
    constructor() {
        this.appConfig = new AppConfig();
        this.domain = this.appConfig.getCoreServiceHost(); 
        this.notificationDomain = this.appConfig.getNotificationServiceHost();
        this.requestService = new RequestService(this.domain);
        this.notificationRequestService = new RequestService(this.notificationDomain);
        this.authService = new AuthService();
    }


    async pingCore() {
        try {
            let loggedInGcid = this.authService.getUser();;
            const url = '/api/dashboard/coreping';
            let result = await this.requestService.fetchJson(url);
            return (result.data == "ping");
        }
        catch (err) {
            console.log(err);
            return false;
        }
    }

    async pingCoreDb() {
        try {
            let loggedInGcid = this.authService.getUser();;
            const url = '/api/dashboard/coredbping';
            let result = await this.requestService.fetchJson(url);
            return (result.data == "ping");
        }
        catch (err) {
            console.log(err);
            return false; 
        }
    }


    async fetchBatchStatusByHour(daysOld) {
        try {
            let gcid = this.authService.getGcid();
            //let qString = "status=" + postStatus;
            const path = '/api/observations/dashboard/gcid/' + gcid + '/postresultbyhour/daysold/' + daysOld;
            var url = path; // + "?" + qString;
            let result = await this.requestService.fetchJson(url);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchBatchStatusByHourByParams(searchParams) {
        try {
            let gcid = this.authService.getGcid();
            let qString = searchParams.asQueryString();
            const path = '/api/observations/dashboard/gcid/' + gcid + '/postresultbyhour/search';
            var url = path + "?" + qString;
            let result = await this.requestService.fetchJson(url);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchOrgConfiguration() {
        try {
            let gcid = this.authService.getGcid();
            //let qString = "status=" + postStatus;
            const path = '/api/observations/dashboard/gcid/' + gcid + '/orgconfig';
            var url = path; // + "?" + qString;
            let result = await this.requestService.fetchJson(url);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchObservationFullSchema(schemaId) {
        try {
          let gcid = this.authService.getGcid();
          //let qString = "status=" + postStatus;
          const path =
            "/api/observations/schema/gcid/" + gcid + "/schema/full/" + schemaId;
          var url = path; // + "?" + qString;
          let result = await this.requestService.fetchJson(url);
          let parsedResult = JSON.parse(result.data.fullSchema);
          return parsedResult;
        } catch (err) {
          console.log(err);
          throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
        }
      }

    async fetchObservationSchema(schemaId) {
        try {
            let gcid = this.authService.getGcid();
            //let qString = "status=" + postStatus;
            const path = '/api/observations/dashboard/gcid/' + gcid + '/schema/' + schemaId;
            var url = path; // + "?" + qString;
            let result = await this.requestService.fetchJson(url);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchObservationList(searchParams){
        try {
            console.group("Search Param Change: ");
            console.log(searchParams);
            console.groupEnd();
            
            let gcid = this.authService.getGcid();
            let qString = searchParams.asQueryString();
            const path = '/api/observations/gcid/' + gcid + '/qaobservation';
            var url = path + "?" + qString;
            let observations = await this.requestService.fetchJson(url);
            return observations;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchObsGradePieList(searchParams){
        try {
            let gcid = this.authService.getGcid();
            let qString = searchParams.asQueryString();
            const path = '/api/observations/dashboard/gcid/' + gcid + '/countbygrade';
            var url = path + "?" + qString;
            let observations = await this.requestService.fetchJson(url);
            return observations;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchObsGrowerGradeList(searchParams){
        try {
            let gcid = this.authService.getGcid();
            let qString = searchParams.asQueryString();
            const path = '/api/observations/dashboard/gcid/' + gcid + '/countbygrowergrade';
            var url = path + "?" + qString;
            let observations = await this.requestService.fetchJson(url);
            return observations;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async requestObsRankList(searchParams, rankSchema){
        try {
            let gcid = this.authService.getGcid();
            let qString = searchParams.asQueryString();
            console.groupEnd();
            const path = "/api/observations/dashboard/gcid/" + gcid + '/rankrequest';
            var url = path + "?" + qString;
            console.log("Search Url: ");
            console.log(url);
            console.log("RankSchema: ");
            console.log(rankSchema);
            let postResult = await this.requestService.postJson(url, rankSchema, true);
            return postResult;
          } catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
          }
    }

    async fetchObsRanchRankList(searchParams){
        try {
            let gcid = this.authService.getGcid();
            let qString = searchParams.asQueryString();
            const path = '/api/observations/dashboard/gcid/' + gcid + '/avgweightedscore';
            var url = path + "?" + qString;
            let observations = await this.requestService.fetchJson(url);
            return observations;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async refreshToDateLocationData(){
        try {
            let gcid = this.authService.getGcid();
            const path = '/api/observations/dashboard/gcid/' + gcid + '/location/todatecount';
            let toDateData = await this.requestService.fetchJson(path);
            return toDateData;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    



    downloadZippedAssets(correlationid) {
        // straight file download
        let loggedInGcid = this.authService.getUser();
        const path = '/api/dashboard/gcid/' + loggedInGcid + '/zipfile/' + correlationid;
        this.requestService.downloadFile(path);
    }


    async fetchDetailedObservationDataAsCsv(searchParams){
        try {
            let gcid = this.authService.getGcid();
            let qString = searchParams.asQueryString();
            
            const path = '/api/observations/gcid/' + gcid + '/qaobservation';
            var url = path + "?" + qString + "&aszip=true";
            //this.requestService.downloadFile(url);
            await this.requestService.fetchJsonFile(url, true, "observations");
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchNotificationSubscriberList(gcid){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/notification/gcid/' + gcid + '/notificationsubscriber';
            //this.requestService.downloadFile(url);
            let responseResult = await this.notificationRequestService.fetchJson(path, true);
            console.log("Subscribers Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }

    async deleteNotificationSubscriber(gcid, subscriberId){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/notification/gcid/' + gcid + '/notificationsubscriberdeleterequest/id/' + subscriberId;
            //this.requestService.downloadFile(url);
            let responseResult = await this.notificationRequestService.postJson(path, "", true);
            console.log("Delete Subscriber Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }


    async postAddNotificationSubscriber(gcid, subscriber){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/notification/gcid/' + gcid + '/notificationsubscriberaddrequest';
            var postObj = JSON.stringify(subscriber);
            console.log("Post Obj:");
            console.log(postObj);
            let responseResult = await this.notificationRequestService.postJson(path, postObj, true);
            console.log("Add Subscriber Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }

    async postUpdateNotificationSubscriber(gcid, subscriber){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/notification/gcid/' + gcid + '/notificationsubscriberupdaterequest';
            var postObj = JSON.stringify(subscriber);
            console.log("Post Obj:");
            console.log(postObj);
            let responseResult = await this.notificationRequestService.postJson(path, postObj, true);
            console.log("Update Subscriber Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }

    async fetchNotificationSubscriberRuleGroupList(gcid){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/notification/gcid/' + gcid + '/notificationrulegroupsubscriber';
            //this.requestService.downloadFile(url);
            let responseResult = await this.notificationRequestService.fetchJson(path, true);
            console.log("Rule Group Subscribers Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }



    async postAddNotificationRuleGroupSubscriber(gcid, ruleGroupSubscriber){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/notification/gcid/' + gcid + '/notificationrulegroupsubscriberaddrequest';
            var postObj = JSON.stringify(ruleGroupSubscriber);
            console.log("Post Obj:");
            console.log(postObj);
            let responseResult = await this.notificationRequestService.postJson(path, postObj, true);
            console.log("Add RuleGroupSubscriber Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }

    async deleteNotificationRuleGroupSubscriber(gcid, id){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/notification/gcid/' + gcid + '/notificationrulegroupsubscriberdeleterequest/id/' + id;
            //this.requestService.downloadFile(url);
            let responseResult = await this.notificationRequestService.postJson(path, "", true);
            console.log("Delete RuleGroupSubscriber Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }



    async fetchUserList(gcid){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/users/gcid/' + gcid + '/gcauthclient';
            //this.requestService.downloadFile(url);
            let responseResult = await this.requestService.fetchJson(path, true);
            console.log("User list Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }


    async postAddUser(gcid, newUser){
        try {
            var gcid = this.authService.getGcid();
            var encryptor = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            newUser.scopes.push(gcid);
            newUser.scopes.push("qacoreapi");
            newUser.scopes.push("notificationapi");
            var postUser = {
                gcid: gcid, 
                encryptorPassword:  encryptor,
                allowOfflineAccess: false,
                accessTokenLifetime: 8000,
                enabled: true,
                clientId: newUser.id,
                clientName: newUser.name,
                description: newUser.role,
                secret: newUser.password,
                allowedScopes: newUser.scopes
             }
            const path = '/api/users/gcid/' + gcid + '/gcauthclient';
            var postObj = JSON.stringify(postUser);
            console.log("Post Obj:");
            console.log(postObj);
            let responseResult = await this.requestService.postJson(path, postObj, true);
            console.log("Add New User Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }


    async postUpdateUserPassword(gcid, newUser){
        try {
            var gcid = this.authService.getGcid();
            var encryptor = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            newUser.scopes.push(gcid);
            newUser.scopes.push("qacoreapi");
            newUser.scopes.push("notificationapi");
            var postUser = {
                gcid: gcid, 
                encryptorPassword:  encryptor,
                allowOfflineAccess: false,
                accessTokenLifetime: 8000,
                enabled: true,
                clientId: newUser.id,
                clientName: newUser.name,
                description: newUser.role,
                secret: newUser.password,
                allowedScopes: newUser.scopes
             }
            const path = '/api/users/gcid/' + gcid + '/gcauthclient';
            var postObj = JSON.stringify(postUser);
            console.log("Post Obj:");
            console.log(postObj);
            let responseResult = await this.requestService.postJson(path, postObj, true);
            console.log("Add New User Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }

    async postUpdateUserEnabledFlag(gcid, userId, isEnabled){
        try {
            var gcid = this.authService.getGcid();
            const path = '/api/users/gcid/' + gcid + '/gcauthclientenabledflagrequest/id/' + userId + '/enabled/' + isEnabled;
            let responseResult = await this.requestService.postJson(path, "", true);
            console.log("Set Enabled Flag Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }

    }


    async postAddOrgConfigObserver(gcid, newUser){
        try {
            var gcid = this.authService.getGcid();
            var postObserver = {
                id: newUser.id,
                gcid: gcid, 
                fullName: newUser.name,
                title: newUser.role,
                scopes: [],
                locations: []
             }
            const path = '/api/observations/admin/gcid/' + gcid + '/orgconfig/observer';
            var postObj = JSON.stringify(postObserver);
            console.log("Post Obj:");
            console.log(postObj);
            let responseResult = await this.requestService.postJson(path, postObj, true);
            console.log("Add New QA Inspector (Observer) Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async postDeleteOrgConfigObserver(gcid, observerId){
        try {
            var gcid = this.authService.getGcid();
            const path = '/api/observations/admin/gcid/' + gcid + '/orgconfig/observerdeleterequest/id/' + observerId;
            let responseResult = await this.requestService.postJson(path, "", true);
            console.log("Remove QA Inspector (Observer) Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }



    async postAddOrgConfigLocation(gcid, newLocation){
        try {
            var gcid = this.authService.getGcid();
            var postLocation = {
                id: newLocation.id,
                gcid: gcid, 
                name: newLocation.name,
                description: newLocation.description,
                timeZoneDesignator: newLocation.timeZoneDesignator
            }
            const path = '/api/observations/admin/gcid/' + gcid + '/orgconfig/location';
            var postObj = JSON.stringify(postLocation);
            console.log("Post Obj:");
            console.log(postObj);
            let responseResult = await this.requestService.postJson(path, postObj, true);
            console.log("Add New Location Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async postDeleteLocation(gcid, locationId){
        try {
            var gcid = this.authService.getGcid();
            const path = '/api/observations/admin/gcid/' + gcid + '/orgconfig/locationdeleterequest/id/' + locationId;
            let responseResult = await this.requestService.postJson(path, "", true);
            console.log("Location Deleted Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }

    }

    async postUpdateLocationEnabledFlag(gcid, locationId, isEnabled){
        try {
            var gcid = this.authService.getGcid();
            const path = '/api/observations/admin/gcid/' + gcid + '/orgconfig/locationactivationrequest/id/' + locationId + '/active/' + isEnabled;
            let responseResult = await this.requestService.postJson(path, "", true);
            console.log("Set Location Enabled Flag Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }

    }



    async deleteUser(gcid, clientId){
        try {
            let gcid = this.authService.getGcid(); 
            const path = '/api/users/gcid/' + gcid + '/gcauthclientdeleterequest/id/' + clientId;
            //this.requestService.downloadFile(url);
            let responseResult = await this.requestService.postJson(path, "", true);
            console.log("Delete User Response Result: ");
            console.log(responseResult);
            return responseResult.data;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        } 
    }

    async retireUser(gcid, clientId){
        //
    }



}