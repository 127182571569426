import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import PrivacyBanner from 'react-cookie-consent';

import LoginPage from "../pages/LoginPage"
import MainLayout from "../layouts/MainLayout"

import bgImage from "assets/images/swimmingjellies.png";




export default class AuthLayout extends Component {
  componentDidMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }


  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname) {
      case "/auth/login-page":
        pageClass = " login-page";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  }


  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <div>
        <div className="wrapper wrapper-full-page">
          <div className={"full-page" + this.getPageClass()} data-color="black" data-image={bgImage}>
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
          <div className="content">
              <Switch>
                  <Route path="/auth/login-page" component={LoginPage}/>
                  <Route path="/main/home" component={MainLayout} />
              </Switch>
            </div>
          <div>
              <PrivacyBanner
                location="bottom"
                buttonText="I Understand"
                cookieName="glasschain-privacy-informed"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                //onAccept={() => { alert("You have accepted receiving Glasschain cookies.") }}
                //debug={true} // note that debugging is on while we demo this feature. 
              >
                Somewhat ironically, the only cookie Glasschain Dashboard uses is to confirm that you have been informed that we do not use any other trackers or analytics bugs.
              We encourage you to check out our <Link to="privacy-page">Privacy Policy Page</Link>
              </PrivacyBanner>
            </div>



        </div>
      </div>
    );
  }
}
