import React, { PureComponent, Component } from "react";
import { FormControl, InputGroup, Card, Row, Col } from "react-bootstrap";
import RecursiveDataLister from "@unleashit/recursive-data-lister";
import _ from "lodash";

function toFixed(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

let omitByDeep = (obj, shouldOmit, convertBool, convertFloat) => {
  obj = _.clone(obj);
  _.forEach(obj, (value, key) => {
    if (shouldOmit(value, key)) {
      delete obj[key];
    }
    if (convertBool) {
      if (_.isBoolean(value)) {
        obj[key] = value ? "YES" : "NO";
      }
    }
    if (convertFloat) {
      if (Number.isFinite(value)) {
        if (!Number.isInteger(value)) {
          // must be a float?
          obj[key] = toFixed(value, 2);
        }
      }
    }
    if (_.isPlainObject(value)) {
      obj[key] = omitByDeep(value, shouldOmit, convertBool, convertFloat);
    }
  });
  return obj;
};

export default class ObservationDetailCard extends Component {
  constructor(props) {
    super(props);

  }


  render() {
    const displayGridRow = this.props.currDetailedGridRow
      ? omitByDeep(
          this.props.currDetailedGridRow,
          (v) => v === null || v === undefined || v === "" || v === 0,
          true,
          true
        ) // immutable. Will NOT change original rowData
      : {"data": {}, "score" : {}, "identifiers": {}};
    if (this.props.currDetailedGridRow) {
      console.log(this.props.currDetailedGridRow);
      // users do not want to see OUI. I'm doing all of this JUST so I can change the label! Stupid.
      displayGridRow.data.underInspection = displayGridRow.data.oui; // again, will NOT change the original object
      delete displayGridRow.data.oui;
      delete displayGridRow.data.readOnly;
    }


    return (
      <Card>
        <Card.Header align="center">Observation Detail</Card.Header>
        <Card.Body>
          <div style={{ width: "100%", height: "100%" }}>
            <Row>
              <Col>
              <RecursiveDataLister
              data={displayGridRow.data}
              displayAsList={false} // For array only: show first level children as separate parents. False is default (outputs as a single top level object)
              tag="ul" // parent html tag. ul is default, you can also choose ol or div
              arrayLeafPropName="title" // if set, this will use the property as node labels for arrays of objects (instead of the index). Careful with this, it only works with one property!
              repeatLeafPropName={false} // true is default. If arrayLeafPropName is set, this will remove the prop from the array so it isn't repeated
            />
              </Col>
              <Col>
              <RecursiveDataLister
              data={displayGridRow.score}
              displayAsList={false} // For array only: show first level children as separate parents. False is default (outputs as a single top level object)
              tag="ul" // parent html tag. ul is default, you can also choose ol or div
              arrayLeafPropName="title" // if set, this will use the property as node labels for arrays of objects (instead of the index). Careful with this, it only works with one property!
              repeatLeafPropName={false} // true is default. If arrayLeafPropName is set, this will remove the prop from the array so it isn't repeated
            />      
              </Col>
              <Col>
              <RecursiveDataLister
              data={displayGridRow.identifiers}
              displayAsList={false} // For array only: show first level children as separate parents. False is default (outputs as a single top level object)
              tag="ul" // parent html tag. ul is default, you can also choose ol or div
              arrayLeafPropName="title" // if set, this will use the property as node labels for arrays of objects (instead of the index). Careful with this, it only works with one property!
              repeatLeafPropName={false} // true is default. If arrayLeafPropName is set, this will remove the prop from the array so it isn't repeated
            />   
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
