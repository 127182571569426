import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GlasschainHttpClient from "../../../providers/http/GlasschainHttpClient.js";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";

function toPieData(apiData) {
  const rawLabels = apiData.map((rec) => {
    return rec.scoreGrade + " (" + rec.pct + "%)";
  });
  const rawData = apiData.map((rec) => {
    return rec.count;
  });

  const rawBkg = apiData.map((rec) =>
  {
    return gradeToColor(rec.scoreGrade);
  })

  //const rawBkg = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  var result = { labels: rawLabels, data: rawData, backgroundColor: rawBkg };
  return result;
}


  // ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  function gradeToColor(grade)
  {
    if (grade==="PREMIUM" || grade==="EXCELLENT") return '#0088fe';
    if (grade==="FANCY" || grade==="GOOD") return '#00C49F';
    if (grade==="STANDARD" || grade==="FAIR") return '#FFBB28';
    if (grade==="POOR" || grade==="REJECT") return '#FF8042';
    return '#D9DDE3';
  }

    // ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    function gradeToOrder(grade)
    {
      if (grade==="PREMIUM" || grade==="EXCELLENT") return 3;
      if (grade==="FANCY" || grade==="GOOD") return 2;
      if (grade==="STANDARD" || grade==="FAIR") return 1;
      if (grade==="POOR") return 0;
      return 4;
    }

export default class ObservationsGradePieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pieData: [{ data: [100], backgroundColor: "white" }],
      pieLabels: ["Loading.."],
      isLoading: true,
      moreCaret: "caret-up",
      isCollapsed: false,
    };

    this.httpClient = new GlasschainHttpClient();
    this.refreshPieData = this.refreshPieData.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  async componentDidMount() {
    if (this.props.searchParams) {
      await this.refreshPieData();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.gridQueryString != prevProps.gridQueryString) {
      await this.refreshPieData();
    }
  }

  async refreshPieData() {
    this.setState({ isLoading: true });
    var pieDataResponse = await this.httpClient.fetchObsGradePieList(
      this.props.searchParams
    );
    var conformedData = toPieData(pieDataResponse.data);
    var newPieData = [
      {
        data: conformedData.data,
        backgroundColor: conformedData.backgroundColor,
      },
    ];
    var newPieLabels = conformedData.labels;
    this.setState({
      pieData: newPieData,
      pieLabels: newPieLabels,
      isLoading: false,
    });
  }

  toggleCollapse() {
    let newIsCollapsed = !this.state.isCollapsed;
    let newCaretState = newIsCollapsed ? "caret-down" : "caret-up";
    this.setState({
      isCollapsed: newIsCollapsed,
      moreCaret: newCaretState,
    });
  }

  render() {
    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon="sync-alt" color="green" spin />
    ) : (
      ""
    );

    return (
      <Card style={{ responsive: "true", padding: "5px" }}>
        <Card.Header onClick={this.toggleCollapse}>
          <span style={{ float: "left" }}>
            <FontAwesomeIcon icon={this.state.moreCaret} />
            &nbsp;&nbsp;Grade Chart
          </span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        {!this.state.isCollapsed ? (
          <Card.Body>
            <Pie
              data={{
                datasets: this.state.pieData,
                labels: this.state.pieLabels,
              }}
              height="95%"
              legend={{ position: "left", align: "left" }}
            />
            </Card.Body>
          ) : (
            <span />
          )}

      </Card>
    );
  }
}
