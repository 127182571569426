
function hasMethod (obj, name) {
    const desc = Object.getOwnPropertyDescriptor (obj, name);
    return !!desc && typeof desc.value === 'function';
  }

export default class ObsSearchParams{
    constructor(params){
        this.maxCount = "";
        this.schemaId = "";
        this.id = "";
        this.gcid = ""
        this.startDate = "";
        this.endDate = "";
        this.inspector = "";
        this.location = "";
        this.grade =  ""; // ok or error or all
        this.grower = "";
        this.ranch = "";
        this.workflowStep = "";
    }




    asQueryString(){
        var obj = {
            "maxcount": this.maxCount,
            "schemaid": this.schemaId,
            "id": this.id,
            "gcid": this.gcid,
            "location": this.location,
            "inspector": this.inspector,
            "startdate": this.startDate,
            "enddate": this.endDate,
            "grade": this.grade,
            "growernbr": this.grower,
            "ranchnbr": this.ranch,
            "workflowstep": this.workflowStep
        }
        var allKeysWithValues = [];
        Object.keys(obj).forEach(function(key) {
            if (obj[key] === undefined) return;
            if (obj[key] === "" || obj[key]==="ALL" || obj[key]==="all") return;
            allKeysWithValues.push(key + '=' +  obj[key]);
        });
          var result = allKeysWithValues.join('&');
          return result;
    }


    validate(){
        return true;
    }
}