import React, { Component } from "react";
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "providers/auth/AuthService.js";
import GlasschainHttpClient from "providers/http/GlasschainHttpClient.js";
import LocationAdminGridCard from "../cards/LocationAdminGridCard.jsx"



export default class LocationAdminPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

  }





  render() {
    const loadingIcon = this.state.isLoading ? (
        <FontAwesomeIcon icon="sync-alt" color="green" spin />
      ) : (
        ""
      );

      const card = this.state.isCollapsed ? 
        (<span/>) :
        (<LocationAdminGridCard
        />)

    return (
      <Card fluid>
        <Card.Header align="center" onClick={this.toggleCollapse}>
          Location Management
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <Card.Body style={{ padding: 10 }}>
          <Container fluid>
            {card}
          </Container>
        </Card.Body>
      </Card>
    );
  }
}
