import React, { Component } from "react";
import {Button, Modal} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationTriangle,faExclamationCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export default class ModalAlertDialog extends Component {

  render() {

    const icon = (this.props.iconType==="error") ? <FontAwesomeIcon icon={faExclamationTriangle} color="red" size="2x"/> :
        (this.props.iconType==="warning") ? <FontAwesomeIcon icon={faExclamationCircle} color="yellow" size="2x"/> :
            <FontAwesomeIcon icon={faInfoCircle} color="green" size="2x"/>;

    return (
          <Modal show={this.props.show} onHide={this.props.onClose}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {icon}&nbsp;{this.props.message}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.onClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

    );
  }
}
