import React, { Component } from "react";
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "providers/auth/AuthService.js";
import GlasschainHttpClient from "providers/http/GlasschainHttpClient.js";
import NotificationAdminGridCard from "../cards/NotificationAdminGridCard.jsx";
import NotificationRuleGroupAdminGridCard from "../cards/NotificationRuleGroupAdminGridCard.jsx";


export default class NotificationAdminPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

    this.onMasterUpdate = this.onMasterUpdate.bind(this);
  }

  onMasterUpdate(){
    var newMasterUpdateToggle = (this.state.masterUpdateToggle===1 ? 2 : 1);
    this.setState({masterUpdateToggle: newMasterUpdateToggle})
  }


  render() {
    const loadingIcon = this.state.isLoading ? (
        <FontAwesomeIcon icon="sync-alt" color="green" spin />
      ) : (
        ""
      );

      const card = this.state.isCollapsed ? 
        (<span/>) :
        (<NotificationAdminGridCard
          onMasterUpdate={this.onMasterUpdate}
        />)

        const ruleCard = this.state.isCollapsed ? 
        (<span/>) :
        (<NotificationRuleGroupAdminGridCard
          hadMasterUpdate={this.state.masterUpdateToggle}
        />)


    return (
      <Card fluid>
        <Card.Header align="center" onClick={this.toggleCollapse}>
          Notifications Management
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <Card.Body style={{ padding: 10 }}>
          <Container fluid>
              {card}
              {ruleCard}
          </Container>
        </Card.Body>
      </Card>
    );
  }
}
