// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faBuilding, faUser, faUnlock, faEye, faEyeSlash, faCircle, faEllipsisH, 
        faEllipsisV, faFileMedicalAlt, faSyncAlt, faHeartbeat, faCaretDown, faCaretUp, faCaretLeft, faCaretRight,
         faSave, faBookmark, faTrashRestore, faInfo,
        faFileExport, faFileDownload, faUserPlus, faUserSlash, faUserEdit, faUsersCog, faKey, faImages,
        faUmbrellaBeach, faUserClock, faMapPin, faSlash, faGlobeAmericas, faBed } from '@fortawesome/free-solid-svg-icons';
    


library.add(
    faBuilding,
    faUser,
    faUnlock,
    faEye,
    faEyeSlash,
    faCircle,
    faEllipsisH,
    faEllipsisV,
    faFileMedicalAlt,
    faSyncAlt,
    faHeartbeat,
    faCaretDown, faCaretUp,
    faCaretLeft, faCaretRight,
    faSave,
    faBookmark,
    faTrashRestore,
    faFileExport,
    faFileDownload,
    faInfo,
    faUserPlus,
    faUserSlash,
    faUserEdit,
    faUsersCog,
    faKey,
    faImages,
    faUmbrellaBeach,
    faUserClock,
    faMapPin,
    faGlobeAmericas,
    faSlash,
    faBed
);