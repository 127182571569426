import React, { Component } from "react";
import { Card, InputGroup, Button, Container, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import Swal from "sweetalert2";
import WithReactContent from "sweetalert2-react-content";


import GlasschainHttpClient from "providers/http/GlasschainHttpClient";
import AuthService from "providers/auth/AuthService";
import withReactContent from "sweetalert2-react-content";
import ObsSearchParams from "models/ObsSearchParams";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";


const DelSwal = withReactContent(Swal);

export default class UserAdminGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [
        { headerName: "userId", field: "clientId", resizable: true, sortable: true, filter: true },
        { headerName: "Name", field: "clientName", resizable: true, sortable: true, filter: true },
        { headerName: "Role", field: "description", resizable: true, sortable: true, filter: true },
        { headerName: "Active", field: "enabled", resizable: true, sortable: true, filter: true },
        { headerName: "Rights", width:200, resizable: true, sortable: true, filter: true, cellRenderer: function (params) { return params.data.allowedScopes.join(',') } }
      ],
      gridData: [],
      currentSelection: null,
      isLoading: true,
      showInputField: false,
      inputMode: null,
      passwordType: "password",
      activeStatus: null
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

    this.gcid = this.auth.getGcid();

    this.refreshGridData = this.refreshGridData.bind(this);
    this.onBtnExportDataAsCsv = this.onBtnExportDataAsCsv.bind(this);
    this.onBtnDeleteSelectedUser = this.onBtnDeleteSelectedUser.bind(this);
    this.onBtnToggleRetireSelectedUser = this.onBtnToggleRetireSelectedUser.bind(this);
    this.onBtnAddUser = this.onBtnAddUser.bind(this);
    this.onBtnCreateUser = this.onBtnCreateUser.bind(this);
    this.onBtnChangeUserPassword = this.onBtnChangeUserPassword.bind(this);
    this.onBtnHandlePasswordChange = this.onBtnHandlePasswordChange.bind(this);
    this.userExists = this.userExists.bind(this);
    this.fieldToggle = this.fieldToggle.bind(this);
    this.togglePasswordShowType = this.togglePasswordShowType.bind(this);
  }

  async componentDidMount() {
    await this.refreshGridData();
  }

  async refreshGridData() {
    console.log("refreshing data");
    this.setState({ isLoading: true });
    var gridDataResponse = await this.httpClient.fetchUserList(this.gcid);
    var newGridData = gridDataResponse.data;
    console.log("Grid data inside refresh func in admin grid: ")
    console.log(newGridData);
    this.setState({
      gridData: newGridData,
      isLoading: false,
      showInputField: false
    });
  }



  onEditGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  togglePasswordShowType(ev){
    ev.preventDefault();
    var newType = (this.state.passwordType === "password") ? "text": "password";
    this.setState({passwordType: newType});
  }

  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    var newActiveStatus = rowData.enabled;
    this.setState({ 
      currentSelection: rowData,
      activeStatus: newActiveStatus });
  }

  // Controls
  onBtnExportDataAsCsv() {
    const saveParams = {
      allColumns: true,
      onlySelected: false,
      onlySelectedAllPages: false,
      skipHeader: false
    };
    this.gridApi.exportDataAsCsv(saveParams);
  }

  async onBtnDeleteSelectedUser() {
    if (!this.state.currentSelection) {
      DelSwal.fire({icon: "error", title: "Select User to Delete", text: "You must select a user from the grid before deleting"});
      return;
    }
    var userId = this.state.currentSelection.clientId;
    DelSwal.fire({
      icon: "question",
      title: "Delete User?",
      text: "Confirm you want to permanently delete " + userId,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    }).then ( async (result) =>{
      if (result.value){
        var userHasObservations = await this.userHasObservations(this.gcid, userId);
        console.log("userHasObservations: " + userHasObservations);
        if (!userHasObservations){
          await this.httpClient.deleteUser(this.gcid, userId);
          await this.httpClient.postDeleteOrgConfigObserver(this.gcid, userId);
          await this.refreshGridData();
          this.setState({ currentSelection: null});
          return DelSwal.fire({icon: "success", text: userId + " has been permanently deleted."});
        }
        else {
          return DelSwal.fire({
            icon: "warning", 
            text: userId + " has created observations. You cannot delete. Do you want to 'retire' the user (disallow observation creation)?",
            title: "Retire User?",
            showCancelButton: true,
            confirmButtonText: "Retire",
            cancelButtonText: "Cancel"
        }).then (async (result) => {
          if (result.value){
            await this.httpClient.postUpdateUserEnabledFlag(this.gcid, userId, false);
            await this.refreshGridData();
            return DelSwal.fire({icon: "success", text: userId + " has been retired and will not be able to create observations."})
          }
        });
        }
      }
    });
  }


  async userHasObservations(gcid, userId){
    var searchParams = new ObsSearchParams();
    searchParams.gcid = gcid;
    searchParams.inspector = userId;
    searchParams.maxCount = 1;
    var dataResult = await this.httpClient.fetchObservationList(searchParams);
    var result = (dataResult.data.length > 0);
    console.log("result is: " + result);
    return result;
  }

  async onBtnToggleRetireSelectedUser(){
    var statusWord = (this.state.activeStatus) ? "retire": "activate"
    if (!this.state.currentSelection) {
      DelSwal.fire({icon: "error", title: "Select user to " + statusWord, text: "You must select a user from the grid to " + statusWord});
      return;
    }
    var userId = this.state.currentSelection.clientId;
    DelSwal.fire({
      icon: "question",
      title: statusWord + " User?",
      text: "Confirm you want to " + statusWord + " user " + userId,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    })
    .then ( async (result) =>{
      if (result.value){
        await this.httpClient.postUpdateUserEnabledFlag(this.gcid, userId, !this.state.activeStatus);
        await this.refreshGridData();
        this.setState({ currentSelection: null});
        return DelSwal.fire({icon: "success", text: userId + " has been " + statusWord + "d."});
    }
  });
}

  


  onBtnAddUser() {
    this.setState({
      inputMode: "add",
      showInputField: true
    })
    //alert(this.state.showInputField);
  }

  onBtnChangeUserPassword() {
    if (!this.state.currentSelection) {
      DelSwal.fire({icon: "error", title: "Select User to Change", text: "You must select a user from the grid before change"});
      return;
    }
    this.setState({
      inputMode: "password",
      showInputField: true
    })
    //alert(this.state.showInputField);
  }

  userExists(userId) {
    var lUserId = userId.toLowerCase();
    for (var i = 0; i < this.state.gridData.length; i++) {
      if (this.state.gridData[i].clientId.toLowerCase() === lUserId) {
        return true;
      }
    }
    return false;
  }

  async onBtnCreateUser(event) {
    event.preventDefault();
    const inputs = event.target.getElementsByTagName('input');
    if (inputs.addUserPassword.value != inputs.addUserPassword2.value){
      alert("Your password values must be the same!")
      return;
    }
    if (inputs.addUserPassword.value.length<6){
      alert("Your password must be at least 6 characters");
      return;
    }
    let newUser =
    {
      id: inputs.addUserID.value,
      gcid: this.gcid,
      name: inputs.addUserName.value,
      nickname: null,
      role: inputs.addUserRole.value,
      password: inputs.addUserPassword.value,
      scopes: []
    };
    if (inputs.gccc.checked) newUser.scopes.push("gccc");
    if (inputs.qainspector.checked) newUser.scopes.push("qainspector");
    if (inputs.qaadmin.checked) newUser.scopes.push("qaadmin");
    console.group("New User:");
    console.log(newUser);
    console.groupEnd();
    if (this.userExists(newUser.id)) {
      alert('This User already exists!');
      return;
    }
    await this.httpClient.postAddUser(this.gcid, newUser);
    if (newUser.scopes.includes("qainspector")){
      // add inspector to the orgconfig inspectors. We will NOT remove them if deleted from
      // here because there may be observation data associated with them. 
      await this.httpClient.postAddOrgConfigObserver(this.gcid, newUser);
    }

    await this.refreshGridData();
  }


  async onBtnHandlePasswordChange(event) {
    event.preventDefault();


    const inputs = event.target.getElementsByTagName('input');
    if (inputs.addUserPassword.value != inputs.addUserPassword2.value){
      alert("Your password values must be the same!")
      return;
    }
    if (inputs.addUserPassword.value.length<6){
      alert("Your password must be at least 6 characters");
      return;
    }
    let newUser =
    {
      id: this.state.currentSelection.clientId,
      gcid: this.gcid,
      name: this.state.currentSelection.clientName,
      role: this.state.currentSelection.description,
      id: inputs.addUserID.value,
      gcid: this.gcid,
      password: inputs.addUserPassword.value,
      scopes: []
    };
    if (inputs.gccc.checked) newUser.scopes.push("gccc");
    if (inputs.qainspector.checked) newUser.scopes.push("qainspector");
    if (inputs.qaadmin.checked) newUser.scopes.push("qaadmin");
    console.group("Updated User :");
    console.log(newUser);
    console.groupEnd();
    await this.httpClient.postUpdateUserPassword(this.gcid, newUser);
    await this.refreshGridData();
  }




  fieldToggle(){
    if(this.state.showInputField){
      this.setState({
        showInputField: false
      })
    } else {
      this.setState({
        showInputField: true
      })
    }
  }

  render() {

    const loadingIcon = (this.state.isLoading) ?
      <FontAwesomeIcon icon="sync-alt" color="green" spin /> :
      ""

    const divStyle = {
      width: "100%",
      height: "calc(45vh)",

      margin: "5px",
      //border: '5px solid black'
    };

    const inputForm = (this.state.currentSelection && this.state.inputMode && this.state.inputMode === "add" ) ?
    <form onSubmit={this.onBtnCreateUser}>
      <input type="text" name="addUserID" placeholder="User Id" required></input>
      <input type="text" name="addUserName" placeholder="Name" required></input>
      <input type="text" name="addUserRole" placeholder="Role" required></input>
      <input type={this.state.passwordType} name="addUserPassword" placeholder="Password" required></input>
      <input type={this.state.passwordType} name="addUserPassword2" placeholder="Password2" required></input>
      <button onClick={(ev) => this.togglePasswordShowType(ev)}>{(this.state.passwordType==="password") ? "Show": "Hide"}</button>
      <fieldset>
        <legend>Security Roles</legend>
        <input type="checkbox" id="qainspector" name="qainspector" value="qainspector"></input>&nbsp;
        <label for="gccc">QA Inspector</label>&nbsp;&nbsp;
        <input type="checkbox" id="gccc" name="gccc" value="gccc"></input>&nbsp;
        <label for="gccc">Control Center User</label>&nbsp;&nbsp;
        <input type="checkbox" id="qaadmin" name="qaadmin" value="qaadmin"></input>&nbsp;
        <label for="qaadmin">QA Administrator</label>
      </fieldset>
      <input type="reset" name="reset" value="Reset" />
      <input type="submit" value="Submit"></input>
      <button onClick={this.fieldToggle}>Cancel</button>
    </form>
    : (this.state.currentSelection && this.state.inputMode && this.state.inputMode === "password") ?
    <form onSubmit={this.onBtnHandlePasswordChange}>
      <input type="text" name="addUserID" placeholder="User Id" value={this.state.currentSelection.clientId} readOnly></input>
      <input type={this.state.passwordType} name="addUserPassword" placeholder="Password" required></input>
      <input type={this.state.passwordType} name="addUserPassword2" placeholder="Password2" required></input>
      <button onClick={(ev) => this.togglePasswordShowType(ev)}>{(this.state.passwordType==="password") ? "Show": "Hide"}</button>
      <fieldset>
        <legend>Security Roles</legend>
        <input type="checkbox" id="qainspector" name="qainspector" value="qainspector" defaultChecked={this.state.currentSelection.allowedScopes.includes("qainspector")}></input>&nbsp;
        <label for="gccc">QA Inspector</label>&nbsp;&nbsp;
        <input type="checkbox" id="gccc" name="gccc" value="gccc" defaultChecked={this.state.currentSelection.allowedScopes.includes("gccc")}></input>&nbsp;
        <label for="gccc">Control Center User</label>&nbsp;&nbsp;
        <input type="checkbox" id="qaadmin" name="qaadmin" value="qaadmin" defaultChecked={this.state.currentSelection.allowedScopes.includes("qaadmin")}></input>&nbsp;
        <label for="qaadmin">QA Administrator</label>
      </fieldset>
      <input type="reset" name="reset" value="Reset" />
      <input type="submit" value="Submit"></input>
      <button onClick={this.fieldToggle}>Cancel</button>
    </form>
    : ""; 

    const columnDefs = (this.state.gridSchema) ? this.state.gridSchema : [];


    const gridData = (this.state.gridData) ? this.state.gridData : [{}];

    return (
      <Card style={{ maxWidth: "2000px", padding: "5px" }}>
        <Card.Header>
          <span style={{ float: "left" }}>Admin User List</span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <ReactTooltip />
        <Card.Body>
          <div style={{ width: "100%", height: "100%" }}>
            <Container fluid>
              <Row>
                <Col sm={7}>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Actions
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <p data-tip="Export User List">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnExportDataAsCsv}>
                        <FontAwesomeIcon icon="file-export" />
                      </Button>
                    </p>
                    <p data-tip="Add User">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnAddUser}>
                        <FontAwesomeIcon icon="user-plus" />
                      </Button>
                    </p>
                    <p data-tip="Remove User">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnDeleteSelectedUser}>
                        <FontAwesomeIcon icon="user-slash" />
                      </Button>
                    </p>
                    <p data-tip={(this.state.activeStatus) ? "Retire User": "Activate User"}>
                      <Button variant="outline-info" size="sm" onClick={this.onBtnToggleRetireSelectedUser}>
                        <FontAwesomeIcon icon={(this.state.activeStatus) ? "umbrella-beach" : "user-clock"} />
                      </Button>
                    </p>
                    <p data-tip="Change User Password">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnChangeUserPassword}>
                        <FontAwesomeIcon icon="key" />
                      </Button>
                    </p>
                  </InputGroup>
                </Col>
                <Col sm={5}>

                </Col>
              </Row>
            </Container>

            <Container>
              {this.state.showInputField && inputForm}
            </Container>

            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={true}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}