import React, { Component } from "react";
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "providers/auth/AuthService.js";
import GlasschainHttpClient from "providers/http/GlasschainHttpClient.js";
import UserAdminGridCard from "../cards/UserAdminGridCard.jsx"


export default class UserAdminPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: false,
      moreCaret: "caret-up",
      isLoading: false,
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }



  toggleCollapse() {
    let newIsCollapsed = !this.state.isCollapsed;
    let newCaretState = newIsCollapsed ? "caret-down" : "caret-up";
    this.setState({
      isCollapsed: newIsCollapsed,
      moreCaret: newCaretState,
    });
  }

  render() {

    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon="sync-alt" color="green" spin />
    ) : (
        ""
      );

    const card = this.state.isCollapsed ?
      (<span />) :
      (<UserAdminGridCard />)

    return (
      <Card fluid>
        <Card.Header align="center" >
          User Management
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <Card.Body style={{ padding: 10 }}>
          <Container fluid>
            {card}
          </Container>
        </Card.Body>
      </Card>
    );
  }
}
