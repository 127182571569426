import React, { Component } from "react";
import {
  CardGroup,
  Card,
  Container,
  Row,
  Col,
  Collapse,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HealthCard from "../../lib/HealthCard.jsx";

export default class HealthStatusCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.refreshHealthData = this.refreshHealthData.bind(this);
  }

  async componentDidMount() {
    // set timer to refresh
    await this.refreshHealthData();
  }

  async componentDidUpdate(prevProps, prevState) {
    // refresh on trigger
  }

  async refreshHealthData() {
    this.setState({ isLoading: true });
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon="sync-alt" color="green" spin />
    ) : (
      ""
    );

    return (
      <CardGroup fluid>
        <HealthCard topic="erp" status="healthy" lastUpdated="" />
        <HealthCard topic="qa core" status="healthy" moreInfo="" lastUpdated="" />
        <HealthCard topic="qa db" status="healthy" moreInfo="" lastUpdated="" />
      </CardGroup>
    );
  }
}
