import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";

import AuthService from "../../providers/auth/AuthService.js";
import LocationAdminPanel from "../../components/local/panels/LocationAdminPanel.jsx";

export default class MgmtPage extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.hasAdminScope = this.auth.hasScope("qaadmin");
  }

  componentDidMount(){
      if (!this.hasAdminScope){
        this.props.history.replace("/");
      }
  }

  render() {

    const adminDiv = (this.hasAdminScope) ?
    <div>
  <Row>
    <Col xs={12}>
      <LocationAdminPanel/>
    </Col>
  </Row>
  </div>
  : <div style={{color: "red", textAlign: "center", marginTop: "5%"}}><h1>You do not have rights to this page</h1></div>

    return (
      <div className="main-content" style={{ padding: "5px" }}>


        {adminDiv}

      </div>
    );
  }
}
