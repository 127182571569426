import React, { Component } from "react";
import { FormControl, InputGroup, Card } from "react-bootstrap";
import "react-dates/initialize";
import { DateRangePicker, toISODateString } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

import ObsSearchParams from "../../../models/ObsSearchParams.js";

export default class ObservationsSearchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null
    }

    this.updateSearchParams = this.updateSearchParams.bind(this);
    this.onDatesChanged = this.onDatesChanged.bind(this);
  }


  updateSearchParams(ev){
    console.log("changing " + ev.target.name + " to " + ev.target.value);
    var newSearchParams = this.props.searchParams;
    newSearchParams[ev.target.name] = ev.target.value;
    this.props.onSearchParamsChange(newSearchParams);
  }

  onDatesChanged(startDate, endDate){
    var newSearchParams = this.props.searchParams;
    if ((startDate==null) || (startDate==="")){
      return;
    }
    if ((endDate==null) || (endDate==="")){
      return;
    }
    // it's also possible the the user has tried to enter it manually
    if (!moment(startDate, 'DD-MM-YY').isValid()){
      return;
    }
    if (!moment(endDate, 'DD-MM-YY').isValid()){
      return;
    }
    newSearchParams.startDate = startDate.format();
    newSearchParams.endDate = endDate.format();
    this.props.onSearchParamsChange(newSearchParams);
  }

  render() {
    let searchParams = this.props.searchParams;
    if (!searchParams) {
      searchParams = new ObsSearchParams();
      searchParams.startDate = moment().subtract(3, "days").format();
      searchParams.endDate = moment().add(1, "days").format();
    }

    let searchFields = (this.props.searchFields) ? this.props.searchFields : [];
 


    return (
      <Card>
        <Card.Header>Filter</Card.Header>
        <Card.Body>
          <div>
            <InputGroup size="sm"  style={{padding: "2px"}}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Location</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                placeholder="location"
                type="dropdown"
                autoComplete="on"
                value={searchParams.location}
                name="location"
                onChange={ev=> this.updateSearchParams(ev)}
              >
                {this.props.orgConfig.locations.map((loc) => (
                  <option>{loc.id}</option>
                ))}
              </FormControl>
            </InputGroup>
            <InputGroup size="sm" style={{padding: "2px"}}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Inspector</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                placeholder="inspector"
                type="dropdown"
                autoComplete="on"
                value={searchParams.inspector}
                name="inspector"
                onChange={ev=> this.updateSearchParams(ev)}
              >
               {this.props.orgConfig.observers.map((inspector) => (
                  <option>{inspector.id}</option>
                ))}
              </FormControl>
            </InputGroup>  


            {searchFields.map(fld =>{
                console.log("SEARCH FIELD: ");
                console.log(fld.id);
                console.log(fld);
                if (fld.options) {
                  console.log("DEAL WITH ADDING ALL");
                  console.log(fld.options);
                  if (!fld.options.some(f => f.id==="ALL")) {
                    fld.options.unshift({ id: "ALL", value: "ALL" });
                  }
                }
                return <InputGroup size="sm"  style={{padding: "2px"}}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">{fld.title}</InputGroup.Text>
                </InputGroup.Prepend>
                {(fld.options) ?
                        <FormControl
                               as="select"
                               placeholder= {fld.title}
                               type="dropdown"
                               autoComplete="on"
                               //value={searchParams.inspector}
                               name={fld.searchParam}
                               onChange={ev=> this.updateSearchParams(ev)}
                             >
                              {fld.options.map((searchFldOpt) => (
                                 <option>{searchFldOpt.value}</option>
                               ))}
                      </FormControl>   
 
                :     <FormControl
                        placeholder={fld.placeholder}
                        //value={searchParams.ranch}
                        name={fld.searchParam}
                        onBlur={ev=> this.updateSearchParams(ev)}
                      />
                }
              </InputGroup>         
              })}
            <InputGroup size="sm"  style={{padding: "2px"}}>
              <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Dates</InputGroup.Text>
              </InputGroup.Prepend>
            <DateRangePicker
              small
              showClearDates={true}
              isOutsideRange={() => false}
              minimumNights={0}
              minDate={moment().subtract(365, "days")}
              startDate={moment(searchParams.startDate)} // momentPropTypes.momentObj or null,
              startDateId="startDateUniqueId" // PropTypes.string.isRequired,
              endDate={moment(searchParams.endDate)} // momentPropTypes.momentObj or null,
              endDateId="endDateUniqueId" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => this.onDatesChanged(startDate, endDate)
                //this.setState({ startDate, endDate })
              } // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            />
            </InputGroup>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
