import React, { Component } from "react";
import {
  Card,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import moment from "moment";
import ObservationsSearchCard from "../cards/ObservationsSearchCard.jsx";
import ObservationsGridCard from "../cards/ObservationsGridCard.jsx";
import ParamActivityBarChart from "../cards/ParamActivityBarChart.jsx";

import ObservationsRankBarCard3 from "../cards/ObservationsRankBarCard3.jsx";
import ObservationDetailCard from "../cards/ObservationDetailCard.jsx";
import ObservationsGradesPieChart from "../cards/ObservationsGradesPieChart.jsx";

import AuthService from "providers/auth/AuthService.js";
import GlasschainHttpClient from "providers/http/GlasschainHttpClient.js";
import ObsSearchParams from "../../../models/ObsSearchParams.js";
import ObservationsGrowerGradeStackedBarChart from "../cards/ObservationsGrowerGradesStackedBarCard.jsx";

//import VictoryPieChart from "../cards/VictoryPieChart.jsx";

export default class ObservationsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderChildren: false,
      prevRenderFlag: false,
      schemaId: null,
      schema: null,
      selectedId: "",
      currDetailedGridRow: null, // user selects a single observation - most likely from the grid
      searchParams: null,
      orgConfig: null,
      rankingsMultiRow: true 
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

    this.onSearchParamsChange = this.onSearchParamsChange.bind(this);
    this.onDetailedGridRowChange = this.onDetailedGridRowChange.bind(this);

    this.onRankingsHorizontalChange = this.onRankingsHorizontalChange.bind(this);
  }

  async componentDidMount() {
    var newSearchParams = new ObsSearchParams();
    newSearchParams.gcid = this.auth.getGcid();
    newSearchParams.maxCount = 10000;
    // later we'll look for saved searches.
    var newOrgConfig = await this.httpClient.fetchOrgConfiguration();
    newOrgConfig.data.locations.unshift({ id: "ALL" });
    newOrgConfig.data.observers.unshift({ id: "ALL" });
    var newSchemaId = newOrgConfig.data.schemaList[0];

    newSearchParams.schemaId = newSchemaId;
    newSearchParams.location = "ALL";
    newSearchParams.inspector = "ALL";
    newSearchParams.startDate = moment().subtract(3, "days").format();
    newSearchParams.endDate = moment().add(1, "days").format();
    let newSchema = await this.httpClient.fetchObservationFullSchema(newSchemaId);
    this.setState({
      searchParams: newSearchParams,
      orgConfig: newOrgConfig.data,
      schemaId: newSchemaId,
      schema: newSchema,
      gridQueryString: "",
    });
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    // this will NOT render child components until we are sure our orgConfig has been loaded into state. See render, below.
    if (this.state.orgConfig != prevState.orgConfig) {
      this.setState({ renderChildren: true });
    }
  }

  async onSchemaChange(ev) {
    let newSchemaId = ev.target.value;
    let newSearchParams = this.state.searchParams;
    newSearchParams.schemaId = newSchemaId;
    let newGridQueryString = newSearchParams.asQueryString();
    let newSchema = await this.httpClient.fetchObservationFullSchema(newSchemaId);
    this.setState({
      schemaId: newSchemaId,
      schema: newSchema,
      searchParams: newSearchParams,
      gridQueryString: newGridQueryString,
    });
  }

  onSearchParamsChange(newSearchParams) {
    var newGridQueryString = newSearchParams.asQueryString();
    console.log(newGridQueryString);
    this.setState({
      gridQueryString: newGridQueryString,
      searchParams: newSearchParams,
    });
  }

  onDetailedGridRowChange(newDetailedGridRow) {
    this.setState({
      currDetailedGridRow: newDetailedGridRow,
    });
  }

  onRankingsHorizontalChange(){
    var newRankingsMultiRow = (!this.state.rankingsMultiRow);
    this.setState({rankingsMultiRow: newRankingsMultiRow});
  }

  render() {

    if (this.state.renderChildren){
      var rankings = (this.state.schema.ranking) ? this.state.schema.ranking.rankings : [];
      const rankingsCount = rankings.length;
      var rankingsColSize = (rankingsCount>=3) ? 4 :
        (rankingsCount == 2) ? 6 :
        12;
    }




    return this.state.renderChildren ? (
      <Card fluid>
        <Card.Header align="center">
          Observations
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Schema*</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              as="select"
              placeholder="schema"
              type="dropdown"
              autoComplete="on"
              value={this.state.schemaId}
              onChange={(ev) => this.onSchemaChange(ev)}
              name="schemaSelection"
            >
              {this.state.orgConfig.schemaList.map((sch) => (
                <option>{sch}</option>
              ))}
            </FormControl>
          </InputGroup>
        </Card.Header>
        <Card.Body style={{ padding: 10 }}>
          <Container fluid>
            <Row noGutters={true} style={{display: "flex"}}>
              <Col lg={true}>
                <ObservationsSearchCard
                  searchParams={this.state.searchParams}
                  onSearchParamsChange={this.onSearchParamsChange}
                  orgConfig={this.state.orgConfig}
                  schemaId={this.state.schemaId}
                  searchFields={(this.state.schema.search) ? this.state.schema.search.searchFields: null}
                />
                </Col>
                <Col lg={true}>
                <ObservationsGradesPieChart
                  searchParams={this.state.searchParams}
                  gridQueryString={this.state.gridQueryString}
                />
                </Col>
            </Row>

            <Row>
                <Col lg={true}>
                <ObservationsGrowerGradeStackedBarChart
                  searchParams={this.state.searchParams}
                  gridQueryString={this.state.gridQueryString}
                />
                </Col>
            </Row>

            <Row>
                        {rankings.map(rank =>{
                          return <Col className= {(this.state.rankingsMultiRow) ? "col-sm-" + rankingsColSize: "col-sm-12"}>          
                              <ObservationsRankBarCard3
                                searchParams={this.state.searchParams}
                                gridQueryString={this.state.gridQueryString}
                                rankSchema = {rank}
                                isMultiRow = {this.state.rankingsMultiRow}
                                onRankingsHorizontalChange = {this.onRankingsHorizontalChange}
                              />
                            </Col>
                        })}

            </Row>
          



            <Row>
              <Col>
                <ParamActivityBarChart
                  searchParams={this.state.searchParams}
                  gridQueryString={this.state.gridQueryString}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ObservationsGridCard
                  searchParams={this.state.searchParams}
                  schemaId={this.state.schemaId}
                  gridQueryString={this.state.gridQueryString}
                  onDetailedGridRowChange={this.onDetailedGridRowChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ObservationDetailCard
                  currDetailedGridRow={this.state.currDetailedGridRow}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    ) : (
      <div>"Loading..."</div>
    );
  }
}
