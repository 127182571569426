import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import "assets/css/gcdashboard.css";

import 'fontawesome.js';

import PrivateRoute from "components/routing/PrivateRoute.jsx";
import AuthLayout from "layouts/AuthLayout.jsx";
import MainLayout from "layouts/MainLayout.jsx";



ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/auth" component={AuthLayout} />} />
      <PrivateRoute path="/main" component={MainLayout} />} />
      <Redirect from="/" to="/main" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);



