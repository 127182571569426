import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HealthStatusCard from "../cards/HealthStatusCard.jsx";
import ToDateTable from "../cards/ToDateTable.jsx";
import ActivityBarChart from "../cards/ActivityBarChart.jsx";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";

export default class HealthPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      moreCaret: "caret-up",
      isLoading: false,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    let newIsCollapsed = !this.state.isCollapsed;
    let newCaretState = newIsCollapsed ? "caret-down" : "caret-up";
    this.setState({
      isCollapsed: newIsCollapsed,
      moreCaret: newCaretState,
    });
  }

  render() {
    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon="sync-alt" color="green" spin />
    ) : (
      ""
    );

    return (
      <Card fluid>
        <Card.Header align="center" onClick={this.toggleCollapse}>
          Health Status&nbsp;&nbsp;
          {this.state.isCollapsed ? (
            <FontAwesomeIcon icon="heartbeat" color="green"/>
          ) : (
            <span />
          )}
          <span style={{ float: "left" }}>
            <FontAwesomeIcon icon={this.state.moreCaret} />
          </span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <Card.Body style={{ padding: "5px" }}>
          {!this.state.isCollapsed ? (
            <span>
              <HealthStatusCard />
              <ToDateTable />
              <ActivityBarChart />
            </span>
          ) : (
            <span />
          )}
        </Card.Body>
      </Card>
    );
  }
}
