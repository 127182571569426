import React, { Component } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import gcObsLogo from "../../assets/images/glasschain-logo.png"


import AppConfig from "../../providers/config/ConfigService.js"

export default class ModalAboutDialog extends Component {
  render() {

    const version = new AppConfig().getVersion();

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onRequestAboutDialogClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>About Glasschain Control Center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <img
                  src={gcObsLogo}
                  alt="glasschain logo"
                />
              </Col>
              <Col>
                <div center="true">Glasschain Control Center</div>
                <div center="true">Powered by Glassbit</div>
                <div center="true">Version: {version} </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.onRequestAboutDialogClose}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
