import React, { Component } from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import MainNavbar from "../components/local/panels/MainNavbar.jsx";
import Sidebar from "../components/local/panels/Sidebar.jsx";
import AuthService from "../providers/auth/AuthService.js";
import HomePage from "../pages/HomePage.jsx";
import UserMgmtPage from "../pages/management/UserMgmtPage.jsx";
import NotificationMgmtPage from "../pages/management/NotificationMgmtPage.jsx";
import ConfigurationMgmtPage from "../pages/management/ConfigurationMgmtPage.jsx";
import EntDataMgmtPage from "../pages/management/EntDataMgmtPage.jsx";
import LoginPage from "../pages/LoginPage.jsx";
import ModalAboutDialog from "../components/modals/ModalAboutDialog.jsx";

var ps;

export default class MainLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbar: false,
      mini: true,
      showAbout: false,
    };

    this.auth = new AuthService();
    this.onRequestLogout = this.onRequestLogout.bind(this);
    this.onRequestAboutDialogOpen = this.onRequestAboutDialogOpen.bind(this);
    this.onRequestAboutDialogClose = this.onRequestAboutDialogClose.bind(this);
    this.onRouteHomeRequest = this.onRouteHomeRequest.bind(this);
    this.onRouteUserMgmtRequest = this.onRouteUserMgmtRequest.bind(this);
    this.onRouteNotificationMgmtRequest = this.onRouteNotificationMgmtRequest.bind(this);
    this.onRouteConfigurationMgmtRequest = this.onRouteConfigurationMgmtRequest.bind(this);
    this.onRouteEntDataMgmtRequest = this.onRouteEntDataMgmtRequest.bind(this);
  }

  async componentDidMount() {
    if (!this.auth.loggedIn()) {
      this.props.history.replace("/auth/login-page");
    }
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    clearInterval(this.interval);
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }

  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  };

  onRequestLogout() {
    this.props.history.replace("/auth/login-page");
  }

  onRouteHomeRequest(){
    this.props.history.replace("/main/home-page");
  }


  onRouteUserMgmtRequest(){
      this.props.history.replace("/main/admin/usermgmt-page");
  }

  onRouteNotificationMgmtRequest(){
    this.props.history.replace("/main/admin/notifymgmt-page");
  }

  onRouteConfigurationMgmtRequest(){
    this.props.history.replace("/main/admin/configmgmt-page");
  }

  onRouteEntDataMgmtRequest(){
    this.props.history.replace("/main/admin/entdatamgmt-page");
  }


  onRequestAboutDialogOpen() {
    this.setState({ showAbout: true });
  }


  onRequestAboutDialogClose() {
    this.setState({ showAbout: false });
  }

  render() {
    return (
      <div className="wrapper">
        <Sidebar {...this.props} 
            mini={this.state.mini} 
            onRequestAboutDialogOpen={this.onRequestAboutDialogOpen}
            onRouteHomeRequest={this.onRouteHomeRequest}
            onRouteMgmtRequest={this.onRouteMgmtRequest}
            onRouteUserMgmtRequest={this.onRouteUserMgmtRequest}
            onRouteNotificationMgmtRequest = {this.onRouteNotificationMgmtRequest}
            onRouteConfigurationMgmtRequest = {this.onRouteConfigurationMgmtRequest}
            onRouteEntDataMgmtRequest = {this.onRouteEntDataMgmtRequest}
        />
        <div className="main-panel" ref="mainPanel">
          <MainNavbar
            {...this.props} 
            handleMiniClick={this.handleMiniClick}
            onLoginExpire={this.onRequestLogout}
            onRequestAboutDialogOpen={this.onRequestAboutDialogOpen}
            onRouteHomeRequest={this.onRouteHomeRequest}
            onRouteMgmtRequest={this.onRouteMgmtRequest}
          />
          <Switch>
            <Route
              path="/main/home-page"
              render={(props) => (
                <HomePage {...props} 
                    onLoginExpire={this.onRequestLogout} 
                />
              )}
            />
            <Route
              path="/main/admin/usermgmt-page"
              render={(props) => (
                <UserMgmtPage {...props} 
                    onLoginExpire={this.onRequestLogout} 
                />
              )}
            />
            <Route
              path="/main/admin/notifymgmt-page"
              render={(props) => (
                <NotificationMgmtPage {...props} 
                    onLoginExpire={this.onRequestLogout} 
                />
              )}
            />

            <Route
              path="/main/admin/configmgmt-page"
              render={(props) => (
                <ConfigurationMgmtPage {...props} 
                    onLoginExpire={this.onRequestLogout} 
                />
              )}
            />

            <Route
              path="/main/admin/entdatamgmt-page"
              render={(props) => (
                <EntDataMgmtPage {...props} 
                    onLoginExpire={this.onRequestLogout} 
                />
              )}
            />

            <Route path="/auth/login-page" component={LoginPage} />
            <Redirect from="/" to="/main/home-page" />
            <Redirect from="/main" to="/main/home-page" />
          </Switch>
        </div>

        <ModalAboutDialog
          show={this.state.showAbout}
          onRequestAboutDialogClose={this.onRequestAboutDialogClose}
        />
      </div>
    );
  }
}
