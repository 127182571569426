// "https://gc-qa2-auth-svc.azurewebsites.net"
// "https://gc-qa2-core-svc.azurewebsites.net"
// "https://gc-notification-svc.azurewebsites.net"
// "http://localhost:6001"
// "http://localhost:7001"
// "http://localhost:8001"

var appsettings = [
    {key: "glasschain.auth.host.addr", value: "https://gc-qa2-auth-svc.azurewebsites.net"},
    {key: "glasschain.core.host.addr", value: "https://gc-qa2-core-svc.azurewebsites.net"},
    {key: "glasschain.notification.host.addr", value: "https://gc-notification-svc.azurewebsites.net"},
    //{key: "glasschain.auth.host.addr", value: "http://localhost:6001"},
    //{key: "glasschain.core.host.addr", value: "http://localhost:7001"},
    //{key: "glasschain.notification.host.addr", value: "http://localhost:9001"},
    {key: "glasschain.image.store.addr", value: "https://gcblobstoredurable1.blob.core.windows.net/"},
    {key: "glasschain.obs.image.store.addr", value: "https://gcblobstoredurable1.blob.core.windows.net/gcid"},
    {key: "version", value: "2.15.01"}



]


module.exports = {
    appsettings
}