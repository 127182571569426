import React, { Component } from "react";
import { Card, InputGroup, Button, Container, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ObsSearchParams from "models/ObsSearchParams";


import GlasschainHttpClient from "providers/http/GlasschainHttpClient";
import AuthService from "providers/auth/AuthService";

import {tz} from "../../../assets/data/timezoneList.js";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const DelSwal = withReactContent(Swal);

export default class LocationAdminGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [
        { headerName: "location", field: "id", resizable: true, sortable: true, filter: true },
        { headerName: "Name", field: "name", resizable: true, sortable: true, filter: true },
        { headerName: "Description", field: "description", resizable: true, sortable: true, filter: true },
        { headerName: "Active", field: "active", resizable: true, sortable: true, filter: true },
        { headerName: "Timezone", field: "timeZoneDesignator", resizable: true, sortable: true, filter: true}

      ],
      gridData: [],
      currentSelection: null,
      isLoading: true,
      showInputField: false,
      inputMode: null,
      activeStatus: null
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();

    this.gcid = this.auth.getGcid();

    this.refreshGridData = this.refreshGridData.bind(this);
    this.onBtnExportDataAsCsv = this.onBtnExportDataAsCsv.bind(this);
    this.onBtnAddLocation = this.onBtnAddLocation.bind(this);
    this.onBtnCreateLocation = this.onBtnCreateLocation.bind(this);
    this.locationExists = this.locationExists.bind(this);
    this.fieldToggle = this.fieldToggle.bind(this);

    this.onBtnDeleteSelectedLocation = this.onBtnDeleteSelectedLocation.bind(this);
    this.onBtnToggleRetireSelectedLocation = this.onBtnToggleRetireSelectedLocation.bind(this);
  }

  async componentDidMount() {
    await this.refreshGridData();
  }

  async refreshGridData() {
    console.log("refreshing data");
    this.setState({ isLoading: true });
    var gridDataResponse = await this.httpClient.fetchOrgConfiguration(this.gcid);
    var newGridData = gridDataResponse.data.locations;
    console.log("Grid data inside refresh func in admin grid: ")
    console.log(newGridData);
    this.setState({
      gridData: newGridData,
      isLoading: false,
      showInputField: false
    });
  }



  onEditGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };



  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    var newActiveStatus = rowData.active;
    this.setState({ 
      activeStatus: newActiveStatus,
      currentSelection: rowData });
  }

  // Controls
  onBtnExportDataAsCsv() {
    const saveParams = {
      allColumns: true,
      onlySelected: false,
      onlySelectedAllPages: false,
      skipHeader: false
    };
    this.gridApi.exportDataAsCsv(saveParams);
  }



  onBtnAddLocation() {
    this.setState({
      inputMode: "add",
      showInputField: true
    })
    //alert(this.state.showInputField);
  }



  locationExists(locationId) {
    var lId = locationId.toLowerCase();
    for (var i = 0; i < this.state.gridData.length; i++) {
      if (this.state.gridData[i].id.toLowerCase() === lId) {
        return true;
      }
    }
    return false;
  }

  async onBtnCreateLocation(event) {
    event.preventDefault();
    const inputs = event.target.getElementsByTagName('input');
    console.log('inputs');
    console.log(inputs);
    var tzValue = document.getElementById('addTimeZoneDesignator').value;
    console.log("Timezone value");
    console.log(tzValue);
    let newLocation =
    {
      id: inputs.addLocationID.value,
      gcid: this.gcid,
      name: inputs.addLocationName.value,
      description: inputs.addLocationDescription.value,
      timeZoneDesignator: tzValue
    };
    console.group("New Location:");
    console.log(newLocation);
    console.groupEnd();
    if (this.locationExists(newLocation.id)) {
      alert('This Location already exists!');
      return;
    } 
    await this.httpClient.postAddOrgConfigLocation(this.gcid, newLocation);
    await this.refreshGridData();
  }


  async onBtnDeleteSelectedLocation() {
    if (!this.state.currentSelection) {
      DelSwal.fire({icon: "error", title: "Select Location to Delete", text: "You must select a location from the grid before deleting"});
      return;
    }
    var locationId = this.state.currentSelection.id;
    DelSwal.fire({
      icon: "question",
      title: "Delete Location?",
      text: "Confirm you want to permanently delete " + locationId,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    }).then ( async (result) =>{
      if (result.value){
        var locationHasObservations = await this.locationHasObservations(this.gcid, locationId);
        console.log("locationHasObservations: " + locationHasObservations);
        if (!locationHasObservations){
          await this.httpClient.postDeleteLocation(this.gcid, locationId);
          await this.refreshGridData();
          this.setState({ currentSelection: null});
          return DelSwal.fire({icon: "success", text: locationId + " has been permanently deleted."});
        }
        else {
          return DelSwal.fire({
            icon: "warning", 
            text: locationId + " has observations. You cannot delete. Do you want to 'retire' the location (disallow observation creation from this location)?",
            title: "Retire Location?",
            showCancelButton: true,
            confirmButtonText: "Retire",
            cancelButtonText: "Cancel"
        }).then (async (result) => {
          if (result.value){
            await this.httpClient.postUpdateLocationEnabledFlag(this.gcid, locationId, false);
            await this.refreshGridData();
            return DelSwal.fire({icon: "success", text: locationId + " has been retired and will not be a valid location for creating observations."})
          }
        });
        }
      }
    });
  }


  async locationHasObservations(gcid, locationId){
    var searchParams = new ObsSearchParams();
    searchParams.gcid = gcid;
    searchParams.location = locationId;
    searchParams.maxCount = 1;
    var dataResult = await this.httpClient.fetchObservationList(searchParams);
    var result = (dataResult.data.length > 0);
    console.log("result is: " + result);
    return result;
  }

  async onBtnToggleRetireSelectedLocation(){
    var statusWord = (this.state.activeStatus) ? "retire": "activate"
    if (!this.state.currentSelection) {
      DelSwal.fire({icon: "error", title: "Select location to " + statusWord, text: "You must select a location from the grid to " + statusWord});
      return;
    }
    var locationId = this.state.currentSelection.id;
    DelSwal.fire({
      icon: "question",
      title: statusWord + " Location?",
      text: "Confirm you want to " + statusWord + " location " + locationId,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    })
    .then ( async (result) =>{
      if (result.value){
        await this.httpClient.postUpdateLocationEnabledFlag(this.gcid, locationId, !this.state.activeStatus);
        await this.refreshGridData();
        this.setState({ currentSelection: null});
        return DelSwal.fire({icon: "success", text: locationId + " has been " + statusWord + "d."});
    }
  });
}






  fieldToggle(){
    if(this.state.showInputField){
      this.setState({
        showInputField: false
      })
    } else {
      this.setState({
        showInputField: true
      })
    }
  }

  render() {

    const loadingIcon = (this.state.isLoading) ?
      <FontAwesomeIcon icon="sync-alt" color="green" spin /> :
      ""


    var tzList = tz.map(zn => {
        return zn.value
    });


    const divStyle = {
      width: "100%",
      height: "calc(45vh)",

      margin: "5px",
      //border: '5px solid black'
    };

    const inputForm = 
    <form onSubmit={this.onBtnCreateLocation}>
      <input type="text" name="addLocationID" placeholder="Location" required></input>
      <input type="text" name="addLocationName" placeholder="Name" required></input>
      <input type="text" name="addLocationDescription" placeholder="Description" required></input>
      <select id="addTimeZoneDesignator" name="addTimeZoneDesignator" placeholder="Timezone" required>
            {tzList.map(z=>{
                return <option value={z}>{z}</option>
            })}
      </select>
      <input type="reset" name="reset" value="Reset" />
      <input type="submit" value="Submit"></input>
      <button onClick={this.fieldToggle}>Cancel</button>
    </form>


    const columnDefs = (this.state.gridSchema) ? this.state.gridSchema : [];


    const gridData = (this.state.gridData) ? this.state.gridData : [{}];

    return (
      <Card style={{ maxWidth: "2000px", padding: "5px" }}>
        <Card.Header>
          <span style={{ float: "left" }}>Observation Locations</span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <ReactTooltip />
        <Card.Body>
          <div style={{ width: "100%", height: "100%" }}>
            <Container fluid>
              <Row>
                <Col sm={7}>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Actions
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <p data-tip="Export Location List">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnExportDataAsCsv}>
                        <FontAwesomeIcon icon="file-export" />
                      </Button>
                    </p>
                    <p data-tip="Add Location">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnAddLocation}>
                        <FontAwesomeIcon icon="map-pin" />
                      </Button>
                    </p>
                    <p data-tip="Remove Location">
                      <Button variant="outline-info" size="sm" onClick={this.onBtnDeleteSelectedLocation}>
                        <FontAwesomeIcon icon="slash" />
                      </Button>
                    </p>
                    <p data-tip={(this.state.activeStatus) ? "Retire Location": "Activate Location"}>
                      <Button variant="outline-info" size="sm" onClick={this.onBtnToggleRetireSelectedLocation}>
                        <FontAwesomeIcon icon={(this.state.activeStatus) ? "bed": "globe-americas"} />
                      </Button>
                    </p>
                  </InputGroup>
                </Col>
                <Col sm={5}>

                </Col>
              </Row>
            </Container>

            <Container>
              {this.state.showInputField && inputForm}
            </Container>

            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={true}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}