import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import logo from "../../../assets/images/glasschain-img-only.png";
import bkgImage from "../../../assets/images/glasschain-jelliesOnly-Narrow.png";
import ConfigService from "../../../providers/config/ConfigService.js";
import AuthService from "../../../providers/auth/AuthService.js";

import sandglassIcon from "../../../assets/images/sandglass-small.png";
import glasskey from "../../../assets/images/glass-key.png";


var ps;

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      openAvatar: false,
      openInfo: false,
      openAdmin: false
    };

    this.config = new ConfigService();
    this.auth = new AuthService();

    this.gcid = this.auth.getGcid();
    this.user = this.auth.getUser();
    this.logoUrlBase = this.config.getLogoImageBase();

    this.clickHome = this.clickHome.bind(this);
    this.clickAbout = this.clickAbout.bind(this);
    this.clickUserGuide = this.clickUserGuide.bind(this);
    this.clickMgmt = this.clickMgmt.bind(this);
    this.clickUserMgmt = this.clickUserMgmt.bind(this);
    this.clickNotificationMgmt = this.clickNotificationMgmt.bind(this);
    this.clickConfigurationMgmt = this.clickConfigurationMgmt.bind(this);
    this.clickEntDataMgmt = this.clickEntDataMgmt.bind(this);

  }

  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
        setTimeout(() => {
          ps.update();
        }, 350);
    }
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }


  logout() {
      this.auth.logout();
      this.props.history.push('/login-page');
  }

  clickHome(ev){
    ev.preventDefault();
    this.props.onRouteHomeRequest();
  }

  clickAbout(ev){
    ev.preventDefault();
    this.props.onRequestAboutDialogOpen();
  }

  clickMgmt(ev){
    ev.preventDefault();
    this.props.onRouteMgmtRequest();
  }

  clickUserMgmt(ev){
    ev.preventDefault();
    this.props.onRouteUserMgmtRequest();
  }

  clickNotificationMgmt(ev){
    ev.preventDefault();
    this.props.onRouteNotificationMgmtRequest();
  }

  clickConfigurationMgmt(ev){
    ev.preventDefault();
    this.props.onRouteConfigurationMgmtRequest();
  }

  clickEntDataMgmt(ev){
    ev.preventDefault();
    this.props.onRouteEntDataMgmtRequest();
  }



  clickUserGuide(ev){
    ev.preventDefault();
    window.open(
      "https://gcblobstoredurable1.blob.core.windows.net/userguidesdurable/glasschain-cc-userguide.pdf",
      "_blank"
    );
  }


  render() {
    return (
      <div className="sidebar" data-color="black" data-image={bkgImage} style={{overflow: "hidden"}}>

        <div
          className="sidebar-background"
          style={{ backgroundImage: "url(" + bkgImage + ")" }}
        />

        <div className="logo">
          <a
            className="simple-text logo-mini"
            target="_blank"
          >
            <div className="logo-img">

              <img src={logo} alt="react-logo" 
                onClick={e => { this.clickHome(e)}}
              />
            </div>
          </a>
          &nbsp;
          <a
            onClick={e => { this.clickHome(e)}}
            className="simple-text logo-normal"
            target="_blank"
          >
            home
          </a>
        </div>



        <div className="sidebar-wrapper" ref="sidebarWrapper">

        <div className="user">
            <div className="photo">
              <img src={this.logoUrlBase + this.gcid + "-logo.png"} style={{ height: 15 }} alt={this.gcid} />
            </div>
            <div className="info">
              <a
                href="#nuttin"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ openAvatar: !this.state.openAvatar });
                }}
              >
                <span>
                  User
                  <b
                    className={
                      this.state.openAvatar ? "caret rotate-180" : "caret"
                    }
                  />
                </span>
              </a>
              <Collapse in={this.state.openAvatar}>

              <ul className="nav">
                  <li>
                    <a href="#nuttin" onClick={this.logout.bind(this)}>
                      <span className="sidebar-mini">LO</span>
                      <span className="sidebar-normal">Log Out</span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>



          <div className="user">
          <div className="photo">
              <img src={sandglassIcon} style={{ height: 20 }} alt="info" />
            </div>
            <div className="info">
              <a
                href="#nuttin"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ openInfo: !this.state.openInfo });
                }}
              >
                <span>
                  <b
                    className={
                      this.state.openInfo ? "caret rotate-180" : "caret"
                    }
                  />
                  Information
                </span>
              </a>
              <Collapse in={this.state.openInfo}>
                <div>
                <ul className="nav">
                  <li>
                    <a href="#nuttin" onClick={ev => this.clickAbout(ev)}>
                      <span className="sidebar-mini">AB</span>
                      <span className="sidebar-normal">About</span>
                    </a>
                  </li>
                </ul>
               <ul className="nav">
                  <li>
                    <a href="#nuttin" onClick={ev => this.clickUserGuide(ev)}>
                      <span className="sidebar-mini">UG</span>
                      <span className="sidebar-normal">User Guide</span>
                    </a>
                  </li>
                </ul>
                <ul className="nav">
                  <li>
                    <a href="https://www.glassbit.com" target="_new">
                      <span className="sidebar-mini">GB</span>
                      <span className="sidebar-normal">Glassbit Site</span>
                    </a>
                  </li>
                </ul>
                </div>
              </Collapse>
            </div>
          </div>




          <div className="user">
          <div className="photo">
              <img src={glasskey} style={{ height: 20 }} alt="admin" />
            </div>
            <div className="info">
              <a
                href="#nuttin"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ openAdmin: !this.state.openAdmin });
                }}
              >
                <span>
                  <b
                    className={
                      this.state.openAdmin ? "caret rotate-180" : "caret"
                    }
                  />
                  Administration
                </span>
              </a>
              <Collapse in={this.state.openAdmin}> 
                <div>

                <ul className="nav">
                <li>
                    <a href="#nuttin" onClick={ev => this.clickUserMgmt(ev)}>
                      <span className="sidebar-mini">UM</span>
                      <span className="sidebar-normal">User Mgmt</span>
                    </a>
                  </li>
                </ul>

                <ul className="nav">
                <li>
                    <a href="#nuttin" onClick={ev => this.clickNotificationMgmt(ev)}>
                      <span className="sidebar-mini">NM</span>
                      <span className="sidebar-normal">Notifications Mgmt</span>
                    </a>
                  </li>
                </ul>

                <ul className="nav">
                <li>
                    <a href="#nuttin" onClick={ev => this.clickConfigurationMgmt(ev)}>
                      <span className="sidebar-mini">CM</span>
                      <span className="sidebar-normal">Configuration Mgmt</span>
                    </a>
                  </li>
                </ul>

                <ul className="nav">
                <li>
                    <a href="#nuttin" onClick={ev => this.clickEntDataMgmt(ev)}>
                      <span className="sidebar-mini">EDM</span>
                      <span className="sidebar-normal">Enterprise Data Mgmt</span>
                    </a>
                  </li>
                </ul>

                </div>
              </Collapse>
            </div>
          </div>




        </div>






      </div>
    );
  }
}

export default withRouter(Sidebar);


