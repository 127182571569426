import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GlasschainHttpClient from "../../../providers/http/GlasschainHttpClient.js";



function toChartData(apiData) 
{

  const chartOptions = {
    title: {
      display: true,
      text: "Grower Grades",
      fontSize: 10,
      stacked: true
    },
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }]

    }
  };

// unique growers
const labels = [...new Set(apiData.map(rec => rec.grower))]

// find unique grades
const datasetLabels = [...new Set(apiData.map(rec => rec.scoreGrade))]


var fullDataset = [];
for (var x=0; x<labels.length; x++)
{
  for (var y=0; y<datasetLabels.length; y++)
  {
    let grower = labels[x];
    let grade = datasetLabels[y];
    let exists = false;
    for (var z=0; z<apiData.length; z++)
    {
      if (apiData[z].grower === grower && apiData[z].scoreGrade === grade)
      {
        exists = true;
        fullDataset.push(apiData[z]);
      }
    }
    if (!exists)
    {
      fullDataset.push({grower: grower, scoreGrade: grade, count: 0, pct:0});
    }
  }
}

  // iterate through each grade to fill datasets
  var datasets = [];

    for (var j=0; j<datasetLabels.length; j++)
    {
      var dsetData = [];
      for (var x=0; x<fullDataset.length; x++)
      {
        if (fullDataset[x].scoreGrade === datasetLabels[j])
        {
          dsetData.push(fullDataset[x].pct);
        }

      };
      datasets.push({stack: "key", label: datasetLabels[j], data: dsetData, backgroundColor: gradeToColor(datasetLabels[j]), order: gradeToOrder(datasetLabels[j])});
    }
   
  
  return {options: chartOptions, data: {labels: labels, datasets: datasets}};
}

  // ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  function gradeToColor(grade)
  {
    if (grade==="PREMIUM" || grade==="EXCELLENT") return '#0088fe';
    if (grade==="FANCY" || grade==="GOOD") return '#00C49F';
    if (grade==="STANDARD" || grade==="FAIR") return '#FFBB28';
    if (grade==="POOR" || grade==="REJECT") return '#FF8042';
    return '#D9DDE3';
  }

    // ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    function gradeToOrder(grade)
    {
      if (grade==="PREMIUM" || grade==="EXCELLENT") return 3;
      if (grade==="FANCY" || grade==="GOOD") return 2;
      if (grade==="STANDARD" || grade==="FAIR") return 1;
      if (grade==="POOR") return 0;
      return 4;
    }






export default class ObservationsGrowerGradeStackedBarChart extends Component 
{
  constructor(props) {
    super(props);
    this.state = {
      chartOptions: [],
      chartLabels: ["Loading..."],
      chartData: [0],
      isLoading: true,
      moreCaret: "caret-up",
      isCollapsed: false,
    };

    this.httpClient = new GlasschainHttpClient();
    this.refreshData = this.refreshData.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  async componentDidMount() {
    if (this.props.searchParams) {
      await this.refreshData();
    }
  }

  async componentDidUpdate(prevProps, prevState) 
  {
    if (this.props.gridQueryString != prevProps.gridQueryString) {
      await this.refreshData();
    }
  }

  async refreshData() 
  {
    this.setState({ isLoading: true });
    var dataResponse = await this.httpClient.fetchObsGrowerGradeList(
      this.props.searchParams
    );
    var conformedData = toChartData(dataResponse.data);
    this.setState({
      chartOptions: conformedData.options,
      chartData: conformedData.data,
      isLoading: false
    });
  }

  toggleCollapse() {
    let newIsCollapsed = !this.state.isCollapsed;
    let newCaretState = newIsCollapsed ? "caret-down" : "caret-up";
    this.setState({
      isCollapsed: newIsCollapsed,
      moreCaret: newCaretState,
    });
  }

  render() {
    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon="sync-alt" color="green" spin />
    ) : (
      ""
    );


    return (
      <Card style={{ responsive: "true", padding: "5px" }}>
        <Card.Header onClick={this.toggleCollapse}>
          <span style={{ float: "left" }}>
            <FontAwesomeIcon icon={this.state.moreCaret} />
            &nbsp;&nbsp;Grower Grade Chart
          </span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        {!this.state.isCollapsed ? (
          <Card.Body>
            <Bar
              data={this.state.chartData}
              options={this.state.chartOptions}
              height="100%"
            />
            </Card.Body>
          ) : (
            <span />
          )}

      </Card>
    );
  }
}
