import {appsettings} from './appSettings.js';
const coreServiceHostKey = "glasschain.core.host.addr";
const authServiceHostKey = "glasschain.auth.host.addr";
const notificationServiceHostKey = "glasschain.notification.host.addr";
const imageStoreAddrKey = "glasschain.image.store.addr";
const baseObsImageHostKey = "glasschain.obs.image.store.addr";


export default class ConfigService{
    constructor(){
        this.appsettings = appsettings;
    }

    getCoreServiceHost(){
        return this.getValue(coreServiceHostKey);
    }

    getAuthServiceHost(){
        return this.getValue(authServiceHostKey);
    }

    getNotificationServiceHost(){
        return this.getValue(notificationServiceHostKey);
    }

    getLogoImageBase() {
        return this.getValue(imageStoreAddrKey) + "logosdurable/";
    }

    getObsImageHostAddr(gcid, imgType){
        // this uses a formula of base host address/[gcid]/[imgType] as convention
        let subdir = (imgType==="user") ? "/obs/" : "/obs/slides/";
        var result = this.getValue(baseObsImageHostKey) + "/" + gcid + subdir;
        return result;
    }

    getVersion(){
        return this.getValue("version");
    }


    hasKey(key){
        return this.appsettings.some(o => o.key === key);
    }

    getValue(key){
        let obj = this.appsettings.find(o => o.key === key);
        return obj.value;
    }



    

}