import decode from 'jwt-decode';
import ConfigService from "../config/ConfigService.js"
import axios from "axios";


export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.appConfig = new ConfigService();
        this.domain = domain || this.appConfig.getAuthServiceHost(); 
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.expiresInMilliseconds = this.expiresInMilliseconds.bind(this);
        this.expirationDateTime = this.expirationDateTime.bind(this);
    }

    statusOk(response) {
        return (response.status >= 200 && response.status < 300);
    }

    async loginSuccessful(gcid, uid, secret, isAdmin){
        var qaAdminScope = (isAdmin) ? " qaadmin ": "";
        var params = {
            grant_type: 'client_credentials',
            client_id: uid,
            client_secret: secret,
            scope: gcid + qaAdminScope +  " gccc qacoreapi notificationapi" // note how multiple audiences (resources) are called by a string with space separating them
        }; 
        const formBody = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
        try {
            var config = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    }
                }
            var url= "/connect/token";
            let fullUrl = this.domain + url;
            //let fullUrl = 'https://jsonplaceholder.typicode.com/users';
            const response = await axios.post(fullUrl, formBody, config);
            if (this.statusOk(response)) {
                this.setUser(uid);
                this.setGcid(gcid);
                this.setToken(response.data.access_token);
                //const jsonResponse = await response.json();
                return true;
            } else {
                return false;
                //throw new Error(response.status + response.statusText);
            }

        } catch (error) {
            console.log(error);
            if (error.response.status > 399 && error.response.status < 404) return false;
            throw new Error(error);
        }
    }



    async login(gcid, uid, secret, isAdmin) {
        var qaAdminScope = (isAdmin) ? " qaadmin ": "";
        var params = {
            grant_type: 'client_credentials',
            client_id: uid,
            client_secret: secret,
            scope: gcid + qaAdminScope +  " gccc qacoreapi notificationapi" // note how multiple audiences (resources) are called by a string with space separating them
        }; 
        const formBody = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
        return fetch(`${this.domain}/connect/token`, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            method: 'POST',
            body: formBody
          })
          .then(res => this._checkStatus(res))
          .then(checkedRes => checkedRes.json())
          .then(jsonRes => {
            this.setUser(uid);
            this.setGcid(gcid);
            this.setToken(jsonRes.access_token); // Setting the token in localStorage
            return Promise.resolve(jsonRes);
        })
        .catch((error) => {
          console.log(error);
          this.logout();
          return Promise.reject(error);
        });
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300){ // Success status lies between 200 to 300
            return response;
        }  
        else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    getTokenPayload(token){
        const decoded = decode(token);
        return {"exp": decoded.exp, 
            "expired": (decoded.exp < Date.now() / 1000),
            "name": decoded.name,
            "scope": decoded.scope
        }
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return true;
        }
    }

    expirationDateTime(){
        //in milliseconds
        var profile = this.getProfile();
        return profile.exp * 1000;
    }

    expiresInMilliseconds(){
        var profile = this.getProfile();
        var expireDtTime = new Date(profile.exp*1000);
        var now = new Date();
        var result = expireDtTime.getTime() - now.getTime();
        return result;
    }

    setKey(key, val){
        localStorage.setItem(key, val);
    }

    getKey(key){
        return localStorage.getItem(key);
    }

    keyExists(key){
        return (this.getKey(key)!=null);
    }

    
    setUser(user){
        localStorage.setItem('user', user);
        //localStorage.setItem('user_gcid', gcid);
    }

    getUser(){
        return localStorage.getItem('user');
        //return localStorage.getItem('user_gcid');
    }
    

    setGcid(gcid){
        localStorage.setItem('user_gcid', gcid);
    }

    getGcid(){
        return localStorage.getItem('user_gcid');
    }

    setSchema(schema){
        localStorage.setItem(this.getGcid() + "::currentschema", JSON.stringify(schema));
    }

    getSchema(){
        return JSON.parse(localStorage.getItem(this.getGcid() + "::currentschema"));
    }


    setMainFilter(searchParams){
        localStorage.setItem(this.getGcid() + "::searchparams", JSON.stringify(searchParams));
    }

    getMainFilter(){
        return JSON.parse(localStorage.getItem(this.getGcid() + "::searchparams"));
    }

    clearMainFilter(){
        localStorage.removeItem(this.getGcid() + "::searchparams");
    }


    setUserProp(propKy, propVal){
        var ky = this.getGcid() + ":" + this.getUser() + ":" + propKy;
        localStorage.setItem(ky, propVal);
    }

    getUserProp(propKy){
        var ky = this.getGcid() + ":" + this.getUser() + ":" + propKy;
        return localStorage.getItem(ky);
    }





    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        var token = this.getToken();
        var result = decode(token);
        return result;
    }

    hasScope(testScope){
        var profile = this.getProfile();
        return profile.scope.includes(testScope);
    }


    fetch(url, options, overrideHeaders) {
        // performs api calls sending the required authentication headers
        const headers =  overrideHeaders || {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }


}