import React, { Component } from "react";
import { Card, Container, Row, Col, Collapse, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HttpClient from "../../../providers/http/GlasschainHttpClient.js";

const columns = [
  { dataField: "source", text: "" },
  { dataField: "dtd", text: "Today" },
  { dataField: "wtd", text: "Week" },
  { dataField: "mtd", text: "Month" },
  { dataField: "ytd", text: "Year" },
];

export default class ToDateTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [{source: "Totals", dtd: 0, wtd: 0, mtd: 0, ytd: 0}],
      totalsOnly: true,
      moreCaret: "caret-down",
      isLoading: true
    };
    this.httpClient = new HttpClient();

    this.renderRows = this.renderRows.bind(this);
    this.toggleTotalsOnly = this.toggleTotalsOnly.bind(this);
    this.fetchTotalsOnly = this.fetchTotalsOnly.bind(this);
  }

  async componentDidMount(){
    var newTableDataResult =  await this.httpClient.refreshToDateLocationData();
    var newTableData = newTableDataResult.data.data;
    this.setState({
      tableData: newTableData,
      isLoading: false
    });
  }


  toggleTotalsOnly(){
    let newTotalsOnly = (!this.state.totalsOnly);
    let newCaretState = (newTotalsOnly) ? "caret-down" : "caret-up";
    this.setState({
      totalsOnly: newTotalsOnly,
      moreCaret: newCaretState
    });
  }


  fetchTotalsOnly(){
    let totalsRw = this.state.tableData.find((rw) => {
      return rw.source === "Totals";
    });
    let result = [];
    result.push(totalsRw);
    return result;
  }


  renderRows(){
    var rowsToRender = (this.state.totalsOnly) ? this.fetchTotalsOnly() : this.state.tableData;
    let result = rowsToRender.map((rw, idx) => {
      return (
      <tr>
          <td style={{padding: "2px"}}>{rw.source}</td>
          <td style={{padding: "2px"}}>{rw.dtd}</td>
          <td style={{padding: "2px"}}>{rw.wtd}</td>
          <td style={{padding: "2px"}}>{rw.mtd}</td>
          <td style={{padding: "2px"}}>{rw.ytd}</td>
      </tr>
      )
    })
    return result;
  }

  render() {

    const loadingIcon = (this.state.isLoading) ? 
      <FontAwesomeIcon icon="sync-alt" color="green" spin/> :
      ""

    return (
      <Card border="light" bg="light" fluid>
        <Card.Header align="right" onClick={this.toggleTotalsOnly}>
          <span style={{float: "left"}}>
          <FontAwesomeIcon icon={this.state.moreCaret}/>&nbsp;&nbsp;Enterprise Totals
          </span>
          <span style={{float: "right"}}>{loadingIcon}</span>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive="sm" style={{fontSize: 9}}>
            <thead>
              <tr>
                <th style={{padding: "2px"}}></th>
                <th style={{padding: "2px"}}>Today</th>
                <th style={{padding: "2px"}}>Week</th>
                <th style={{padding: "2px"}}>Month</th>
                <th style={{padding: "2px"}}>Year</th>
              </tr>
            </thead>
            <tbody>
              {this.renderRows()}
            </tbody>

          </Table>
        </Card.Body>
      </Card>
    );
  }
}
