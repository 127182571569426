import React, { Component } from "react";
import {NavDropdown, Dropdown, Navbar, Nav } from "react-bootstrap";
import {Link} from "react-router-dom";
import CircleIcon from "../../lib/CircleIcon.jsx";
import ConfigService from "../../../providers/config/ConfigService.js";
import AuthService from "../../../providers/auth/AuthService.js";
import NavLoginExpireTimer from "../../NavbarLoginExpireTimer.jsx";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "../../../assets/css/override.css";



export default class MainNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      togglerIcon: "ellipsis-v",
      showLoginExpirationMessage: false
    };
    this.auth = new AuthService();
    this.gcid = this.auth.getGcid();
    this.uid = this.auth.getUser();
    this.config = new ConfigService();
    this.logoUrlBase = this.config.getLogoImageBase();


    this.onLoginAboutToExpire = this.onLoginAboutToExpire.bind(this);
    this.clickUserGuide = this.clickUserGuide.bind(this);
    this.clickLogOut = this.clickLogOut.bind(this);
  }

  mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle("nav-open");
  };

  toggleSidebar = (e) => {
    var newTogglerIcon =
      this.state.togglerIcon === "ellipsis-v" ? "ellipsis-h" : "ellipsis-v";
    this.setState({ togglerIcon: newTogglerIcon });
    this.props.handleMiniClick();
  };

  clickUserGuide(ev){
    ev.preventDefault();
    window.open(
      "https://gcblobstoredurable1.blob.core.windows.net/userguidesdurable/glasschain-cc-userguide.pdf",
      "_blank"
    );
  }

clickLogOut() {
    this.auth.logout();
    this.props.history.push('/login-page');
}

  onLoginAboutToExpire(){
    this.state({showLoginExpirationMessage: true});
  }

  render() {
    return (
      <Navbar fluid="true" className={this.props.navbar ? "navbar-fixed" : ""} style={{maxHeight: "100px"}}>
        <div className="navbar-minimize">
          <CircleIcon
            icon={this.state.togglerIcon}
            size="3x"
            // rotate = "90"
            color="gray"
            onClick={this.toggleSidebar.bind(this)}
          />
        </div>

        <Navbar.Brand>{"Glasschain Control Center"}</Navbar.Brand>
        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        <img src={this.logoUrlBase + this.gcid + "-logo.png"} style={{transform: "scale(.5)", marginLeft:"15vw"}} alt={this.gcid} />
        <Dropdown style={{marginLeft: "20vw"}}>
        <Dropdown.Toggle variant="primary" id="dropdown-basic"></Dropdown.Toggle>
        <Dropdown.Menu style={{left: "-100px"}}>
            <Dropdown.Item>
        Login Expires in
              <NavLoginExpireTimer
                beforeExpireWarningMinutes={10}
                autoLogoutMinutes={5}
                timerWarningExpiration={this.onLoginAboutToExpire}
                onLoginExpire={this.props.onLoginExpire}
              />
          </Dropdown.Item>
          
          <Dropdown.Item onClick={this.props.onRouteHomeRequest}>Home</Dropdown.Item>
          <Dropdown.Item onClick={this.props.onRouteMgmtRequest}>Mgmt</Dropdown.Item>
          <Dropdown.Item onClick={this.clickUserGuide}>User Guide</Dropdown.Item>
          <Dropdown.Item onClick={this.clickLogOut}>Log Out</Dropdown.Item>
          <Dropdown.Item onClick={this.props.onRequestAboutDialogOpen}>About..</Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>

        {(this.state.showLoginExpirationMessage) ? 
               <span style={{color: "red"}}>Your Login will expire soon. Please logout and log in again</span>
               : <span/>
        }

      </Navbar>


 
    );
  }
}
