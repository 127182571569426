import React, { Component } from "react";
import { Card, Container, Row, Col, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function statusColor(status){
    switch(status) {
        case "healthy": {return "green"; }
        case "warn": return "yellow";
        case "error": return "darkred";
        default: return "black"
    }
}

export default class HealthCard extends Component{
  constructor(props) {
    super(props);
    this.state = {
        moreInfoCaret: "caret-down"
    }
  }

  moreInfoToggle() {
      var newCaretState = (this.state.moreInfoCaret==="caret-down") ? "caret-up" : "caret-down";
      this.setState({moreInfoCaret: newCaretState});
  }

  render() {


    const Rule = ({ color }) => (
        <hr
          style={{
            borderColor: color,
          }}
        />
      );

      const StatusStyle = ({color, sz, txt}) => (
          <div
              style = {{
                  fontColor: color,
                  fontSize: sz
              }}
          >
          {txt}
          </div>
      )

      const moreCardInfo = (this.props.moreInfo) ?
              <div
                style = {{
                    fontColor: "grey",
                    fontSize : "9px"
                }}
            >
                <span onClick={this.moreInfoToggle.bind(this)}>
                    info&nbsp; <FontAwesomeIcon icon={this.state.moreInfoCaret}/>
                </span>
                <Collapse in={this.state.moreInfoCaret==="caret-up"}>
                    <div id="example-collapse-text">
                        {this.props.moreInfo}
                    </div>
                </Collapse>
            </div>

            : 

            <div
            style = {{
                fontColor: "grey",
                fontSize : "9px"
            }}
            >
                &nbsp;&nbsp;
            </div>
        
  

      

    return (
      <Card border="light" bg="light" fluid>
          <Card.Header>
              {this.props.topic}&nbsp;&nbsp;            
              <FontAwesomeIcon icon="heartbeat" color={statusColor(this.props.status)} size="2x"/>
          </Card.Header>
      </Card>
    );
  }
}
